import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { TextFieldModule } from '@angular/cdk/text-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { OrderByPipe } from './pipes/order.pipe';
import { RemoveCommaPipe } from './pipes/remove-coma.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { DragDropDirective } from './helpers/drag-drop.directive';
import { NavigationComponent } from './components/top-bar/navigation/navigation.component';
import { UserMenuComponent } from './components/top-bar/user-menu/user-menu.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { HighlightDirective } from './directives/highlight.directive';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { NewTagDirective } from './directives/new-tag.directive';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { InputWithUnitComponent } from './components/input-with-unit/input-with-unit.component';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { CentsCurrencyPipe } from './pipes/cents-currency.pipe';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { EditableTextComponent } from './components/editable-text/editable-text.component';
import { UnitPipe } from './pipes/unit.pipe';
import { TooltipDirective } from './directives/tooltip.directive';
import { ConfigConditionComponent } from './components/config-condition/config-condition.component';
import { GenericSubscribeCardComponent } from './components/generic-subscribe-card/generic-subscribe-card.component';
import { TemplateComponent, SubscribeTooltipDirective } from './directives/subscribe-tooltip.directive';
import { MaxWidthTextDirective } from './directives/max-width-text.directive';
import { HerdIconDirective } from './directives/herd-icon.directive';
import { TableColumnEditorComponent } from './components/table-column-editor/table-column-editor.component';
import { HerdNamePipe } from './pipes/herd-name.pipe';
import { PaddockNamePipe } from './pipes/padddock-name.pipe';
import { ImperialToMetricPipe } from './pipes/unit-conversion/imperial-to-metric.pipe';
import { UnitNamePipe } from './pipes/unit-conversion/unit-name.pipe';
import { MetricToImperialPipe } from './pipes/unit-conversion/metric-to-imperial.pipe';
import { DecoratedLinkDirective } from './directives/decorated-link.directive';
import { AnimalCountValidator } from './validators';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { RequestTrackerInterceptor } from './interceptors/request-tracker.interceptor';
import { LabelDirective } from './directives/label.directive';

@NgModule({
  declarations: [
    OrderByPipe,
    RemoveCommaPipe,
    ReplacePipe,
    DragDropDirective,
    NavigationComponent,
    UserMenuComponent,
    TopBarComponent,
    HighlightDirective,
    OutsideClickDirective,
    NewTagDirective,
    ColorPickerComponent,
    InputWithUnitComponent,
    RelativeDatePipe,
    DateTimePickerComponent,
    EditableTextComponent,
    UnitPipe,
    TooltipDirective,
    ConfigConditionComponent,
    GenericSubscribeCardComponent,
    SubscribeTooltipDirective,
    TemplateComponent,
    MaxWidthTextDirective,
    HerdIconDirective,
    TableColumnEditorComponent,
    HerdNamePipe,
    PaddockNamePipe,
    ImperialToMetricPipe,
    MetricToImperialPipe,
    UnitNamePipe,
    CentsCurrencyPipe,
    DecoratedLinkDirective,
    LabelDirective,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    MatNativeDateModule,
    TextFieldModule,
    DragDropModule,
    MatCheckboxModule,
    MatSelectModule,
  ],
  exports: [
    OrderByPipe,
    RemoveCommaPipe,
    ReplacePipe,
    DragDropDirective,
    TopBarComponent,
    HighlightDirective,
    OutsideClickDirective,
    NewTagDirective,
    ColorPickerComponent,
    InputWithUnitComponent,
    RelativeDatePipe,
    DateTimePickerComponent,
    EditableTextComponent,
    UnitPipe,
    TooltipDirective,
    ConfigConditionComponent,
    GenericSubscribeCardComponent,
    SubscribeTooltipDirective,
    MaxWidthTextDirective,
    HerdIconDirective,
    TableColumnEditorComponent,
    HerdNamePipe,
    PaddockNamePipe,
    ImperialToMetricPipe,
    MetricToImperialPipe,
    UnitNamePipe,
    CentsCurrencyPipe,
    DecoratedLinkDirective,
    LabelDirective,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestTrackerInterceptor, multi: true },
    CurrencyPipe,
    CentsCurrencyPipe,
    RelativeDatePipe,
    DatePipe,
    AnimalCountValidator,
  ],
})
export class SharedModule {}
