<div class="field-container" [ngClass]="edit ? 'open' : 'close'">
  <mat-form-field *ngIf="type === 'default'" [appearance]="apperance">
    <input matInput [value]="value" [readonly]="!edit" [placeholder]="placeholder" />
  </mat-form-field>

  <mat-form-field *ngIf="type === 'date'" [appearance]="apperance">
    <input matInput [value]="value" [matDatepicker]="picker" [readonly]="!edit" [placeholder]="placeholder" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker touchUi></mat-datepicker>
  </mat-form-field>

  <mat-form-field *ngIf="type === 'textarea'" [appearance]="apperance">
    <textarea matInput cdkTextareaAutosize [value]="value" [readonly]="!edit" [placeholder]="placeholder"></textarea>
  </mat-form-field>
</div>
