import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Color, ColorsModel } from '../../constants/colors.constant';

@Component({
  selector: `pasture-color-picker`,
  templateUrl: `./color-picker.component.html`,
  styleUrls: [`./color-picker.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent implements OnInit {
  public readonly colors = ColorsModel;

  @Input() currentColor: Color;
  @Output() currentColorChange = new EventEmitter<Color>();
  public opened: boolean;

  ngOnInit(): void {
    if (!this.currentColor) {
      this.pickColor(ColorsModel[0]);
    }
  }

  public pickColor(color: Color): void {
    this.currentColor = color;
    this.currentColorChange.emit(color);
  }

  public toggleSelect(): void {
    this.opened = !this.opened;
  }
}
