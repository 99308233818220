import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: `pasture-confirmation-dialog`,
  templateUrl: `./confirmation-dialog.component.html`,
  styleUrl: `./confirmation-dialog.component.scss`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  public get title(): string {
    return this.data?.title;
  }

  public get content(): string {
    return this.data?.content;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private data: DialogData) {}
}

type DialogData = {
  title: string;
  content: string;
};
