import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { SettingsConfigService } from '../settings/settings-config.service';

@Injectable()
export class GeoSearchService {
  private autocompleteToken: google.maps.places.AutocompleteSessionToken;

  constructor(settingsService: SettingsConfigService) {
    const loader = new Loader({
      apiKey: settingsService.settings.GOOGLE_API_KEY,
      version: `weekly`,
      libraries: [`places`],
    });

    loader.importLibrary(`places`).then((places: google.maps.PlacesLibrary) => {
      this.autocompleteToken = new places.AutocompleteSessionToken();
    });
  }

  public async findByText(text: string): Promise<{ places: google.maps.places.Place[] }> {
    const request = {
      textQuery: text,
      fields: [`location`],
      language: `en-US`,
    };
    const { Place } = (await google.maps.importLibrary(`places`)) as google.maps.PlacesLibrary;
    return Place.searchByText(request);
  }

  public async finishAutocomplete(suggestion: google.maps.places.AutocompleteSuggestion): Promise<google.maps.LatLng> {
    const place = suggestion.placePrediction.toPlace();
    await place.fetchFields({ fields: [`location`] });
    return place.location;
  }

  public async autocomplete(text: string): Promise<google.maps.places.AutocompleteSuggestion[]> {
    const { AutocompleteSuggestion } = (await google.maps.importLibrary(`places`)) as google.maps.PlacesLibrary;

    const request = {
      input: text,
      language: `en-US`,
      sessionToken: this.autocompleteToken,
    };

    const { suggestions } = await AutocompleteSuggestion.fetchAutocompleteSuggestions(request);
    return suggestions;
  }
}
