import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: `pasture-image-preview`,
  templateUrl: `./image-preview.component.html`,
  styleUrls: [`./image-preview.component.scss`],
})
export class ImagePreviewComponent {
  private readonly maxSize = 0.8;

  public src: string;

  public screenWidth: number = window.innerWidth;
  public screenHeight: number = window.innerHeight;
  public imageWidth: number = 0;
  public imageHeight: number = 0;

  private aspectRatio: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private dialogRef: MatDialogRef<ImagePreviewComponent>
  ) {
    this.src = data.src;
  }

  @HostListener(`window:resize`, [`$event`]) onResize(event: Event): void {
    const target = event.target as Window;
    this.screenWidth = target.innerWidth;
    this.screenHeight = target.innerHeight;
    this.updateImageSize();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onImageLoad(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    this.aspectRatio = imgElement.naturalWidth / imgElement.naturalHeight;
    this.updateImageSize();
  }

  private updateImageSize(): void {
    if (!this.aspectRatio) {
      return;
    }

    const maxWidth = this.screenWidth * this.maxSize;
    const maxHeight = this.screenHeight * this.maxSize;

    if (maxWidth / this.aspectRatio <= maxHeight) {
      this.imageWidth = maxWidth;
      this.imageHeight = maxWidth / this.aspectRatio;
    } else {
      this.imageHeight = maxHeight;
      this.imageWidth = maxHeight * this.aspectRatio;
    }
  }
}

type DialogData = {
  src: string;
};
