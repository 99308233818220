import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import LayerGroup from 'ol/layer/Group';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import { Cluster } from 'ol/source';

import { MapViewerService } from './map-viewer.service';
import { InfrastructureColor, InfrastructureType } from '../../models/inventory.interface';

@Component({
  selector: `pasture-map-viewer`,
  templateUrl: `./map-viewer.component.html`,
  styleUrls: [`./map-viewer.component.scss`],
})
export class MapViewerComponent implements OnInit {
  @ViewChild(`mapElement`, { static: true }) mapElement: ElementRef;

  @Output() mapService = new EventEmitter<MapViewerService>();

  // TODO: pass real source URLs
  private readonly mapSources = [
    {
      url: `https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}`,
      attribution: `Tiles © ArcGIS`,
    },
    {
      url: `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
      attribution: `Tiles © ArcGIS`,
    },
  ];

  public currentSourceIndex = 0;

  private map: Map;
  private mapLayer: TileLayer<XYZ>;
  private geometryLayer: LayerGroup;
  private herdLayer: VectorLayer;
  private infrastructureLayer: LayerGroup;
  private labelLayer: VectorLayer;
  private temporaryLayer: VectorLayer;
  private viewerService: MapViewerService;

  ngOnInit(): void {
    this.initializeMap();
    this.viewerService = new MapViewerService(this.map, this.mapElement);
    this.mapService.emit(this.viewerService);
  }

  public changeMapSource(mapIndex: number): void {
    this.mapLayer.getSource().setUrl(this.mapSources[mapIndex].url);
    this.currentSourceIndex = mapIndex;
  }

  private initializeMap(): void {
    this.mapLayer = new TileLayer({
      source: new XYZ({
        url: this.mapSources[0].url,
        attributions: this.mapSources[0].attribution,
      }),
    });

    const standalonePaddockLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        fill: new Fill({
          color: `#00b16a1a`,
        }),
        stroke: new Stroke({
          color: `#ffffff`,
          width: 2,
        }),
      }),
    });

    const parentPaddockLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        stroke: new Stroke({
          color: `#ffffff`,
          width: 2,
        }),
      }),
    });

    const subpaddockLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        fill: new Fill({
          color: `#00b16a1a`,
        }),
        stroke: new Stroke({
          color: `#ffffffaa`,
          width: 1,
        }),
      }),
    });

    this.herdLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const gatesLayer = new VectorLayer({
      source: new Cluster({
        distance: 50,
        source: new VectorSource(),
      }),
      style: this.getInfrastructureClusterStyle(InfrastructureType.Gate),
    });

    const waterLayer = new VectorLayer({
      source: new Cluster({
        distance: 50,
        source: new VectorSource(),
      }),
      style: this.getInfrastructureClusterStyle(InfrastructureType.WaterTrough),
    });

    const balesLayer = new VectorLayer({
      source: new Cluster({
        distance: 50,
        source: new VectorSource(),
      }),
      style: this.getInfrastructureClusterStyle(InfrastructureType.Bale),
    });

    const rainLayer = new VectorLayer({
      source: new Cluster({
        distance: 50,
        source: new VectorSource(),
      }),
      style: this.getInfrastructureClusterStyle(InfrastructureType.RainGauge),
    });

    const photosLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const soilLayer = new VectorLayer({
      source: new Cluster({
        distance: 50,
        source: new VectorSource(),
      }),
      style: this.getInfrastructureClusterStyle(InfrastructureType.SoilPoint),
    });

    const othersLayer = new VectorLayer({
      source: new Cluster({
        distance: 50,
        source: new VectorSource(),
      }),
      style: this.getInfrastructureClusterStyle(InfrastructureType.Other),
    });

    const herdsPathLayer = new VectorLayer({
      source: new VectorSource(),
    });

    this.labelLayer = new VectorLayer({
      source: new VectorSource(),
      declutter: true,
    });

    this.temporaryLayer = new VectorLayer({
      source: new VectorSource(),
    });

    this.herdLayer.setZIndex(1);

    this.geometryLayer = new LayerGroup({ layers: [standalonePaddockLayer, parentPaddockLayer, subpaddockLayer] });
    this.infrastructureLayer = new LayerGroup({
      layers: [gatesLayer, waterLayer, balesLayer, rainLayer, photosLayer, soilLayer, othersLayer, herdsPathLayer],
    });

    this.map = new Map({
      target: this.mapElement.nativeElement,
      layers: [
        this.mapLayer,
        this.geometryLayer,
        this.herdLayer,
        this.infrastructureLayer,
        this.labelLayer,
        this.temporaryLayer,
      ],
      view: new View({
        center: [0, 0],
        zoom: 10,
      }),
    });
  }

  private getInfrastructureClusterStyle(infrastructure: InfrastructureType): (feature: any) => Style {
    const color = InfrastructureColor(infrastructure);
    return (feature): Style => {
      const size = feature.get(`features`).length;
      if (size > 1) {
        const innerFeature = feature.get(`features`)[0];
        const iconCanvas = innerFeature.getStyle().getImage().getImage() as HTMLCanvasElement;
        const newCanvas = this.duplicateCanvas(iconCanvas);
        const style = new Style({
          image: new Icon({
            img: newCanvas,
            size: [newCanvas.width, newCanvas.height],
          }),
        });
        this.viewerService.addNumberTag(newCanvas, size, color);
        return style;
      } else if (size === 1) {
        return feature.get(`features`)[0].getStyle();
      } else {
        return null;
      }
    };
  }

  private duplicateCanvas(originalCanvas: HTMLCanvasElement): HTMLCanvasElement {
    if (!(originalCanvas instanceof HTMLCanvasElement)) {
      throw new Error(`Input must be an HTMLCanvasElement.`);
    }

    // Create a new canvas element
    const duplicatedCanvas = document.createElement(`canvas`);

    // Copy size
    duplicatedCanvas.width = originalCanvas.width;
    duplicatedCanvas.height = originalCanvas.height;

    // Copy drawing context
    const originalContext = originalCanvas.getContext(`2d`);
    const duplicatedContext = duplicatedCanvas.getContext(`2d`);

    if (originalContext && duplicatedContext) {
      duplicatedContext.drawImage(originalCanvas, 0, 0);
    }

    // Return the new canvas
    return duplicatedCanvas;
  }
}
