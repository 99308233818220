import { createSelector } from '@ngrx/store';
import { State as UserState } from './user.state';
import { State as RanchState } from '../ranch/ranch/ranch.state';
import { selectRanchState } from '../ranch/ranch/ranch.selectors';
import { UserRole } from 'src/app/shared/models/user.interface';

const selectUserState = (rootState): UserState => rootState.user;

export const selectMe = createSelector(selectUserState, (state: UserState) => state.me);
export const selectSubscription = createSelector(selectUserState, (state: UserState) => state.sub);
export const selectSubscriptionIsLoaded = createSelector(selectUserState, (state: UserState) => state.subLoaded);
export const selectTeam = createSelector(selectUserState, (state: UserState) => state.ranchTeam);
export const selectRole = createSelector(
  selectUserState,
  selectRanchState,
  (userState: UserState, ranchState: RanchState) => {
    const currentRanchId = ranchState.ranch?.uuid;
    const affiliation = userState.me.ranches?.find((r) => r.ranch_uuid === currentRanchId);
    return affiliation?.role;
  }
);
export const selectCanEdit = createSelector(selectRole, (role: UserRole) => role === `owner` || role === `member`);

export const selectMaxTeamMembers = createSelector(selectSubscription, (sub) => sub?.max_team_members);
export const selectRanchMembersCount = createSelector(
  selectTeam,
  (team) => team.filter((t) => t.role === `member`).length
);
