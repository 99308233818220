import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: `pasture-top-bar`,
  templateUrl: `./top-bar.component.html`,
  styleUrls: [`./top-bar.component.scss`],
})
export class TopBarComponent {
  @Input() navigationHidden = false;
  @Input() userHidden = false;
  @Input() whiteUserButton = false;
  @Input() hideSeasonTag = false;
  public scrolled = false;

  @HostListener(`window:scroll`, [])
  public onWindowScroll(): void {
    if (!window.parent.document.documentElement.classList.contains(`cdk-global-scrollblock`)) {
      this.scrolled = window.scrollY > 10;
    }
  }
}
