<div class="dialog-container">
  <h2 mat-dialog-title>{{ title }}</h2>
  <mat-dialog-content>
    <p>{{ content }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button color="primary" [matDialogClose]="false">No</button>
    <button mat-raised-button color="primary" [matDialogClose]="true">Yes</button>
  </mat-dialog-actions>
</div>
