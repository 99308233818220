import { createReducer, on } from '@ngrx/store';
import { initialState, State } from './ranch.state';
import * as RanchActions from './ranch.actions';

export const ranchReducer = createReducer<State>(
  initialState,
  on(
    RanchActions.GetRanchSuccess,
    (state, { ranch }): State => ({
      ...state,
      ranch,
    })
  ),
  on(
    RanchActions.GetPaddocksSuccess,
    (state, { paddocks }): State => ({
      ...state,
      paddocks,
      paddocksLoaded: true,
      grazeEventsCalculated: false,
    })
  ),
  on(
    RanchActions.GetSeasonsSuccess,
    (state, { seasons }): State => ({
      ...state,
      seasons,
    })
  ),
  on(
    RanchActions.GetInfrastructuresSuccess,
    (state, { infrastructures }): State => ({
      ...state,
      infrastructures,
    })
  ),
  on(
    RanchActions.GrazeEventsCalculated,
    (state, { plannedEvents, historicalEvents, otherPaddockEvents, earliestPossibleMovePerHerd }): State => ({
      ...state,
      plannedEvents,
      historicalEvents,
      otherPaddockEvents,
      earliestPossibleMovePerHerd,
      grazeEventsCalculated: true,
    })
  ),
  on(RanchActions.UpdatePaddockSuccess, (state, { paddock }): State => {
    const oldPaddocks = [...state.paddocks];
    const oldIndex = oldPaddocks.findIndex((p) => p.uuid === paddock.uuid);
    if (oldIndex === -1) {
      oldPaddocks.push(paddock);
    } else {
      oldPaddocks[oldIndex] = paddock;
    }
    return {
      ...state,
      paddocks: oldPaddocks,
    };
  }),
  on(RanchActions.DeletePaddockSuccess, (state, { paddockUuid }): State => {
    const paddocks = state.paddocks.filter((p) => p.uuid !== paddockUuid);
    return {
      ...state,
      paddocks,
    };
  }),
  on(RanchActions.AddInfrastructures, (state, { infrastructures }): State => {
    const newInfrastructures = [...state.infrastructures, ...infrastructures];
    return {
      ...state,
      infrastructures: newInfrastructures,
    };
  }),
  on(RanchActions.UpdateInfrastructures, (state, { infrastructures }): State => {
    const infrastructureIds = infrastructures.map((i) => i.uuid);
    const newInfrastructures = [
      ...state.infrastructures.filter((i) => !infrastructureIds.includes(i.uuid)),
      ...infrastructures,
    ];
    return {
      ...state,
      infrastructures: newInfrastructures,
    };
  }),
  on(RanchActions.DeleteInfrastructures, (state, { infrastructureIds }): State => {
    const newInfrastructures = state.infrastructures.filter((i) => !infrastructureIds.includes(i.uuid));
    return {
      ...state,
      infrastructures: newInfrastructures,
    };
  })
);
