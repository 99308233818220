import { ElementRef, Injectable } from '@angular/core';
import { Feature, Overlay } from 'ol';
import Map from 'ol/Map';
import View from 'ol/View';
import { FeatureLike } from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import { LineString, Point, Polygon } from 'ol/geom';
import { Pixel } from 'ol/pixel';
import VectorLayer from 'ol/layer/Vector';
import * as Extent from 'ol/extent';
import { Circle, Fill, Icon, Stroke, Style, Text as OLText, RegularShape } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import Layer from 'ol/layer/Layer';
import Source from 'ol/source/Source';
import LayerRenderer from 'ol/renderer/Layer';
import { Interaction } from 'ol/interaction';
import { asArray } from 'ol/color';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { boundingExtent } from 'ol/extent';

import { InteractionsService } from './interactions.service';
import { IconService } from './icon.service';

@Injectable()
export class MapViewerService {
  public interactionsService: InteractionsService;

  private iconService: IconService;

  public get view(): View {
    return this.map.getView();
  }

  public get interactionActive(): boolean {
    return this.interactionsService.interactionActive;
  }

  public tooltipElement: HTMLElement;
  public hoveredFeature: FeatureLike;

  private mapClickCallback: () => void;

  constructor(
    private map: Map,
    mapElement: ElementRef
  ) {
    this.tooltipElement = mapElement.nativeElement.querySelector(`.tooltip`);
    this.interactionsService = new InteractionsService(map);
    this.iconService = new IconService();
  }

  public centerOnExtent(extent: Extent.Extent, duration = 1000): void {
    this.view.fit(extent, { duration, maxZoom: 17 });
  }

  public addTooltipListener(): void {
    this.map.on(`pointermove`, (evt) => {
      if (evt.dragging) {
        this.tooltipElement.style.visibility = `hidden`;
        this.hoveredFeature = undefined;
        return;
      }
      const pixel = this.map.getEventPixel(evt.originalEvent);
      if (!this.interactionsService.interactionActive) {
        this.displayTooltip(pixel, evt.originalEvent.target);
      }
    });
  }

  public addClickListener(): void {
    this.map.on(`click`, (event) => {
      const feature = event.map.forEachFeatureAtPixel(event.pixel, (feature) => feature);
      if (this.interactionsService.interactionActive) {
        return;
      }
      if (feature) {
        if (this.featureIsClustered(feature)) {
          this.zoomToCluster(feature);
        } else {
          this.getClusteredFeature(feature).get(`clickCallback`)?.call();
        }
      } else {
        this.mapClickCallback?.call(null);
      }
    });
  }

  public addPolygon(
    coordinates: Coordinate[][],
    geometryLayer: VectorLayer,
    tooltip?: string,
    clickCallback?: () => void
  ): Feature<Polygon> {
    const polygonFeature = new Feature<Polygon>(new Polygon(coordinates).transform(`EPSG:4326`, `EPSG:3857`));
    if (tooltip) {
      polygonFeature.set(`tooltip`, tooltip);
    }
    if (clickCallback) {
      polygonFeature.set(`clickCallback`, clickCallback);
    }

    this.getSource(geometryLayer).addFeature(polygonFeature);
    return polygonFeature;
  }

  public addIcon(
    icon: string,
    color: string,
    layer: VectorLayer,
    coordinate: Coordinate,
    numberTag?: string,
    tooltip?: string,
    clickCallback?: () => void
  ): Feature<Point> {
    const iconFeature = new Feature({
      geometry: new Point(fromLonLat(coordinate)),
    });
    if (tooltip) {
      iconFeature.set(`tooltip`, tooltip);
    }
    if (clickCallback) {
      iconFeature.set(`clickCallback`, clickCallback);
    }
    iconFeature.setStyle(this.iconFeatureStyle(icon, color, numberTag));
    this.getSource(layer).addFeature(iconFeature);

    return iconFeature;
  }

  public iconFeatureStyle(icon: string, color: string, numberTag?: string): Style {
    const canvas = document.createElement(`canvas`) as HTMLCanvasElement;
    canvas.width = 44;
    canvas.height = 44;
    const ctx = canvas.getContext(`2d`);
    const iconUrl = `assets/icons/${icon}_white.svg`;

    // Load the SVG file
    Promise.resolve(this.iconService.createImage(iconUrl)).then((img) => {
      // Draw the colored background
      const iconPadding = 4;
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2 - iconPadding, 0, Math.PI * 2);
      ctx.fill();

      // Draw the SVG image
      const imageMargin = 8;
      ctx.drawImage(img, imageMargin, imageMargin, canvas.width - 2 * imageMargin, canvas.height - 2 * imageMargin);

      // Draw the white border
      ctx.strokeStyle = `white`;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2 - 2 - iconPadding, 0, Math.PI * 2);
      ctx.stroke();

      // Add rounded rectangle with text
      if (numberTag) {
        this.addNumberTag(canvas, numberTag, color);
      }
    });

    return new Style({
      image: new Icon({
        img: canvas,
        size: [canvas.width, canvas.height],
      }),
    });
  }

  public addNumberTag(canvas: HTMLCanvasElement, numberTag: string, color: string): void {
    const ctx = canvas.getContext(`2d`);
    const text = numberTag;
    const textBorder = 2;
    const textPadding = 3;
    const fontSize = 11;
    const boxHeight = fontSize + 2 * textPadding;
    ctx.font = `bold ${fontSize}pt sans-serif`;
    const textWidth = ctx.measureText(text).width;
    const boxWidth = textWidth + 2 * textPadding;
    const boxX = canvas.width - boxWidth - textBorder;
    const boxY = canvas.height - boxHeight - textBorder;
    const cornerRadius = 6;

    ctx.lineWidth = textBorder;
    ctx.beginPath();
    ctx.moveTo(boxX + cornerRadius, boxY);
    ctx.arcTo(boxX + boxWidth, boxY, boxX + boxWidth, boxY + cornerRadius, cornerRadius);
    ctx.arcTo(boxX + boxWidth, boxY + boxHeight, boxX + boxWidth - cornerRadius, boxY + boxHeight, cornerRadius);
    ctx.arcTo(boxX, boxY + boxHeight, boxX, boxY + boxHeight - cornerRadius, cornerRadius);
    ctx.arcTo(boxX, boxY, boxX + cornerRadius, boxY, cornerRadius);
    ctx.closePath();
    ctx.fillStyle = `white`;
    ctx.fill();
    ctx.strokeStyle = color;
    ctx.stroke();

    const textX = boxX + textPadding;
    const textY = canvas.height - textPadding - textBorder;
    ctx.fillStyle = `black`;
    ctx.fillText(text, textX, textY);
  }

  public addRingIcon(
    color: string,
    radius: number,
    width: number,
    layer: VectorLayer,
    coordinate: Coordinate
  ): Feature<Point> {
    const iconFeature = new Feature({
      geometry: new Point(fromLonLat(coordinate)),
    });
    iconFeature.setStyle([
      new Style({
        image: new Circle({
          radius,
          stroke: new Stroke({
            color,
            width,
          }),
        }),
      }),
      new Style({
        image: new Circle({
          radius,
          fill: new Fill({
            color: `#fff0`,
          }),
        }),
      }),
    ]);

    this.getSource(layer).addFeature(iconFeature);

    return iconFeature;
  }

  public addLabel(text: string, layer: VectorLayer, coordinates: Coordinate): Feature<Point> {
    const textFeature = new Feature(new Point(coordinates).transform(`EPSG:4326`, `EPSG:3857`));
    const offset = -30;
    textFeature.setStyle([
      new Style({
        image: new RegularShape({
          stroke: new Stroke({ color: `white`, width: 2 }),
          points: 2,
          radius: 14,
          displacement: [0, 14],
        }),
        text: new OLText({
          text: text,
          font: `500 14px Poppins,sans-serif`,
          fill: new Fill({ color: `#000` }),
          stroke: new Stroke({ color: `#fff`, width: 6 }),
          overflow: true,
          textAlign: `center`,
          textBaseline: `middle`,
          offsetY: offset,
        }),
      }),
    ]);
    this.getSource(layer).addFeature(textFeature);
    return textFeature;
  }

  public registerMapClickCallback(callback: () => void): void {
    this.mapClickCallback = callback;
  }

  public getAllLayers(): Layer<Source, LayerRenderer<any>>[] {
    return this.map.getAllLayers();
  }

  public drawPolygon(layer: VectorLayer): void {
    // const interaction = new Draw({
    //   type: `Polygon`,
    //   source: layer.getSource(),
    // });
    // this.map.addInteraction(interaction);
    // interaction.on(`drawend`, () => this.map.removeInteraction(interaction));
  }

  public addLine(coordinates: Coordinate[], layer: VectorLayer): Feature<LineString> {
    const lineFeature = new Feature<LineString>(new LineString(coordinates).transform(`EPSG:4326`, `EPSG:3857`));
    this.getSource(layer).addFeature(lineFeature);
    return lineFeature;
  }

  public addDirectionArrowsToLine(line: Feature<LineString>, color: string): Feature<LineString> {
    const additionalStyles: Style[] = [];
    const arrowColor = asArray(color);
    arrowColor[3] = 1;
    line.getGeometry().forEachSegment((start, end) => {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const xLocation = (end[0] * 2 + start[0]) / 3;
      const yLocation = (end[1] * 2 + start[1]) / 3;
      const rotation = Math.atan2(dy, dx);
      additionalStyles.push(
        new Style({
          geometry: new Point([xLocation, yLocation]),
          image: new Icon({
            src: `assets/img/arrow_bigger.png`,
            rotateWithView: true,
            rotation: -rotation,
            color: `white`,
          }),
        }),
        new Style({
          geometry: new Point([xLocation, yLocation]),
          image: new Icon({
            src: `assets/img/arrow.png`,
            rotateWithView: true,
            rotation: -rotation,
            color: arrowColor,
          }),
        })
      );
    });

    const currentStyle = line.getStyle();
    const styleArray = (Array.isArray(currentStyle) ? currentStyle : [currentStyle]) as Style[];
    line.setStyle([...styleArray, ...additionalStyles]);
    return line;
  }

  public addDotsToLine(line: Feature<LineString>, color: string): Feature<LineString> {
    const additionalStyles: Style[] = [];
    const arrowColor = asArray(color);
    arrowColor[3] = 1;
    line
      .getGeometry()
      .getCoordinates()
      .forEach((coord) => {
        additionalStyles.push(
          new Style({
            geometry: new Point(coord),
            image: new Circle({
              radius: 4,
              fill: new Fill({
                color: color,
              }),
              stroke: new Stroke({
                color: `white`,
                width: 1,
              }),
            }),
          })
        );
      });

    const currentStyle = line.getStyle();
    const styleArray = (Array.isArray(currentStyle) ? currentStyle : [currentStyle]) as Style[];
    line.setStyle([...styleArray, ...additionalStyles]);

    return line;
  }

  public addInteraction(interaction: Interaction): void {
    this.interactionsService.addInteraction(interaction);
  }

  public removeInteraction(interaction: Interaction): void {
    this.interactionsService.removeInteraction(interaction);
  }

  public removeAllInteractions(): void {
    this.interactionsService.removeAllInteractions();
  }

  public featuresAtCoordinate(coordinate: Coordinate): FeatureLike[] {
    const pixel = this.map.getPixelFromCoordinate(coordinate);
    return this.map.getFeaturesAtPixel(pixel);
  }

  public addOverlay(overlay: Overlay): void {
    this.map.addOverlay(overlay);
  }

  public removeOverlay(overlay: Overlay): void {
    this.map.removeOverlay(overlay);
  }

  public getSource(layer: VectorLayer): VectorSource {
    const source = layer.getSource();
    if (source instanceof Cluster) {
      return source.getSource();
    }
    return source;
  }

  public sourceIsClustered(source: VectorSource): boolean {
    return source instanceof Cluster;
  }

  public featureIsClustered(feature: FeatureLike): boolean {
    return feature?.get(`features`)?.length > 1;
  }

  private displayTooltip(pixel: Pixel, target: HTMLElement): void {
    const relevantFeature = target.closest(`.ol-control`)
      ? undefined
      : this.map.forEachFeatureAtPixel(pixel, (feature) => feature);
    const feature = this.getClusteredFeature(relevantFeature);
    if (feature?.get(`tooltip`)) {
      this.tooltipElement.style.transform = `translate(${pixel[0] + 15}px, ${pixel[1] + 5}px)`;
      if (feature !== this.hoveredFeature) {
        this.tooltipElement.style.visibility = `visible`;
        this.tooltipElement.innerHTML = feature.get(`tooltip`);
      }
    } else {
      this.tooltipElement.style.visibility = `hidden`;
    }
    this.hoveredFeature = feature;
  }

  private getClusteredFeature(feature: FeatureLike): FeatureLike {
    const childFeatures = feature?.get(`features`);
    if (!childFeatures) {
      return feature;
    } else if (childFeatures.length === 1) {
      return childFeatures[0];
    }
    return feature;
  }

  private zoomToCluster(cluster: FeatureLike): void {
    const paddingPercentage = 0.2; // percentage of the area that should be padded around the cluster

    const features = cluster.get(`features`);
    if (features.length > 1) {
      const extent = boundingExtent(features.map((r) => r.getGeometry().getCoordinates()));
      const mapSize = this.map.getSize();
      const horizontalPad = (mapSize[0] * (1 - paddingPercentage)) / 2;
      const verticalPad = (mapSize[1] * (1 - paddingPercentage)) / 2;
      this.view.fit(extent, { duration: 1000, padding: [verticalPad, horizontalPad, verticalPad, horizontalPad] });
    }
  }
}
