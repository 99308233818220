import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { ErrorTextGeneratorService } from '../../services/error-text-generator.service';

@Component({
  selector: `pasture-editable-text`,
  templateUrl: `./editable-text.component.html`,
  styleUrls: [`./editable-text.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableTextComponent {
  @Input() control: FormControl = new FormControl();

  public get value(): any {
    return this.control.value;
  }
  @Input() set value(v: any) {
    this.control.setValue(v);
  }
  @Output() valueChange = new EventEmitter<any>();

  @Input() edit: boolean;
  @Input() apperance: MatFormFieldAppearance;
  @Input() type: `default` | `date` | `textarea` = `default`;
  @Input() placeholder: string;

  constructor(protected errorText: ErrorTextGeneratorService) {}
}
