import { Directive, Input, ElementRef, Renderer2, ViewContainerRef } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
  selector: `[pastureOverlaySpinner]`,
})
export class OverlaySpinnerDirective {
  public get pastureOverlaySpinner(): boolean {
    throw new Error(`Set-only property`);
  }
  @Input() set pastureOverlaySpinner(show: boolean) {
    show ? this.showOverlay() : this.hideOverlay();
  }

  private overlay: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef
  ) {
    this.createOverlay();
  }

  private createOverlay(): void {
    this.renderer.setStyle(this.el.nativeElement, `position`, `relative`);

    this.overlay = this.renderer.createElement(`div`);
    this.renderer.setStyle(this.overlay, `position`, `absolute`);
    this.renderer.setStyle(this.overlay, `top`, `0`);
    this.renderer.setStyle(this.overlay, `left`, `0`);
    this.renderer.setStyle(this.overlay, `bottom`, `0`);
    this.renderer.setStyle(this.overlay, `right`, `0`);
    this.renderer.setStyle(this.overlay, `backgroundColor`, `rgba(255, 255, 255, 0.85)`);
    this.renderer.setStyle(this.overlay, `backdrop-filter`, `blur(1px)`);
    this.renderer.setStyle(this.overlay, `display`, `flex`);
    this.renderer.setStyle(this.overlay, `justifyContent`, `center`);
    this.renderer.setStyle(this.overlay, `alignItems`, `center`);
    this.renderer.setStyle(this.overlay, `zIndex`, `1000`);
    this.renderer.setStyle(this.overlay, `transition`, `all 0.1s ease-in-out`);

    const spinnerComponentRef = this.viewContainerRef.createComponent(MatProgressSpinner);
    spinnerComponentRef.instance.mode = `indeterminate`;

    const spinner = spinnerComponentRef.location.nativeElement;
    this.renderer.appendChild(this.el.nativeElement, this.overlay);
    setTimeout(() => {
      this.renderer.appendChild(this.overlay, spinner);
    });
  }

  private showOverlay(): void {
    this.renderer.setStyle(this.overlay, `opacity`, `1`);
    this.renderer.setStyle(this.overlay, `visibility`, `visible`);
  }

  private hideOverlay(): void {
    this.renderer.setStyle(this.overlay, `opacity`, `0`);
    this.renderer.setStyle(this.overlay, `visibility`, `hidden`);
  }
}
