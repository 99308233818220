import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImagePreviewComponent } from './image-preview/image-preview.component';

@Component({
  selector: `pasture-photo-upload`,
  templateUrl: `./photo-upload.component.html`,
  styleUrls: [`./photo-upload.component.scss`],
})
export class PhotoUploadComponent {
  private _readonly: boolean;
  public get readonly(): boolean {
    return this._readonly;
  }
  @Input() set readonly(value: boolean) {
    this._readonly = value;
    this.photoSrc = this._initialPhoto;
  }
  private _initialPhoto: string;
  get initialPhoto(): string {
    return this._initialPhoto;
  }
  @Input() set initialPhoto(url: string) {
    this.photoSrc = url;
    this._initialPhoto = url;
  }
  @Output() fileChanged = new EventEmitter<File>();
  protected photoSrc: string;

  constructor(private dialog: MatDialog) {}

  public deletePhoto(): void {
    this.photoSrc = null;
    this.fileChanged.emit(null);
  }

  protected setImage(file: File): void {
    this.photoSrc = URL.createObjectURL(file);
    this.fileChanged.emit(file);
  }

  protected seePhoto(): void {
    this.dialog.open(ImagePreviewComponent, { data: { src: this.photoSrc } });
  }
}
