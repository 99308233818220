import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: `pasture-date-time-picker`,
  templateUrl: `./date-time-picker.component.html`,
  styleUrls: [`./date-time-picker.component.scss`],
})
export class DateTimePickerComponent implements OnInit {
  @Input() apperance: MatFormFieldAppearance = `fill`;
  @Input() control: FormControl<Date>;
  @Input() label: string;
  @Input() optional: boolean;
  private _date: Date;
  public get date(): Date {
    return this._date;
  }
  @Input() set date(date: Date) {
    this.setDate(date);
  }
  private _disabled: boolean;
  public get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(d: boolean) {
    this._disabled = d;
    d ? this.control?.disable() : this.control?.enable();
  }
  @Input() error: string;
  @Output() dateChange = new EventEmitter<Date>();

  private _min: Date;
  public get min(): Date {
    return this._min;
  }
  @Input() set min(m: Date) {
    if (this.inErrorState) {
      return;
    }
    this._min = m;
    this.setTimeBounderies();
    this.fixHoursOutsideOfPermittedRange();
  }

  private _max: Date;
  public get max(): Date {
    return this._max;
  }
  @Input() set max(m: Date) {
    if (this.inErrorState) {
      return;
    }
    this._max = m;
    this.setTimeBounderies();
    this.fixHoursOutsideOfPermittedRange();
  }

  public timeMin: string;
  public timeMax: string;

  public _time: string;
  public get time(): string {
    return this._time;
  }
  public set time(time: string) {
    this._time = time;
    if (this.date) {
      this.appendTimeToDate(time);
    }
  }
  public timeErrorMatcher: ErrorStateMatcher;
  public inErrorState: boolean;

  ngOnInit(): void {
    if (this.control?.value) {
      this.date = this.control.value;
    }
    this.setTimeFromDate(this.date);
    if (this.control) {
      this._disabled = this.control.disabled;
    } else {
      this.control = new FormControl<Date>(this.date);
    }
    this.control.setValue(this.date);
    if (this._disabled) {
      this.control.disable();
    }
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((v) => {
      this.date = v;
    });
    this.control.registerOnDisabledChange((isDisabled) => {
      this._disabled = isDisabled;
    });
    this.timeErrorMatcher = new TimeErrorStateMatcher(this.control);
  }

  private appendTimeToDate(time: string): void {
    if (!time || !this.date) {
      return;
    }
    const currentHours = this.date.getHours();
    const currentMinutes = this.date.getMinutes();
    const [hours, minutes] = time.split(`:`);
    if (currentHours !== Number(hours) || currentMinutes !== Number(minutes)) {
      this.date.setHours(Number(hours));
      this.date.setMinutes(Number(minutes));
      this.control.updateValueAndValidity();
    }
  }

  private setTimeFromDate(date: Date): void {
    this._time = date ? this.dateToTimeParser(date) : ``;
  }

  private fixHoursOutsideOfPermittedRange(): void {
    if (this.min && this.max && this.min.getTime() > this.max.getTime()) {
      this.inErrorState = true;
      this.control.addValidators(() => ({ errorState: true }));
      this.control.updateValueAndValidity();
      throw new RangeError(`Min greater than Max`);
    }
    if (!this.date || !this.time) {
      return;
    }
    if (this.min && this.date.valueOf() < this.min.valueOf() && this.equalDateDay(this.date, this.min)) {
      this.setTimeFromDate(this.min);
      this.appendTimeToDate(this.time);
    }
    if (this.max && this.date.valueOf() > this.max.valueOf() && this.equalDateDay(this.date, this.max)) {
      this.setTimeFromDate(this.max);
      this.appendTimeToDate(this.time);
    }
  }

  private equalDateDay(date1: Date, date2: Date): boolean {
    return (
      date1 &&
      date2 &&
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  private setTimeBounderies(): void {
    if (this.equalDateDay(this.date, this.min)) {
      this.timeMin = this.dateToTimeParser(this.min);
    } else {
      this.timeMin = null;
    }
    if (this.equalDateDay(this.date, this.max)) {
      this.timeMax = this.dateToTimeParser(this.max);
    } else {
      this.timeMax = null;
    }
  }

  private dateToTimeParser(date: Date): string {
    return `${date.getHours()}:${date.getMinutes()}`;
  }

  private setDate(date: Date): void {
    this._date = date;
    if (!date) {
      return;
    }
    if (!this.time) {
      this.setTimeFromDate(date);
    } else {
      this.appendTimeToDate(this.time);
    }
    this.setTimeBounderies();
    this.fixHoursOutsideOfPermittedRange();
    this.control?.setValue(date, { emitEvent: false });
    this.dateChange.emit(date);
  }
}

class TimeErrorStateMatcher implements ErrorStateMatcher {
  constructor(private dateControl: AbstractControl) {}
  isErrorState(_: any, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      this.dateControl &&
      this.dateControl.invalid &&
      (this.dateControl.dirty || this.dateControl.touched || isSubmitted)
    );
  }
}
