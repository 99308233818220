<div class="main-container">
  <div class="left">
    <img class="logo" src="assets/img/logo.svg" alt="PastureMap" />
    <h1>Join the 10,000+ ranchers on PastureMap</h1>
    <ul class="fa-ul">
      <li>
        <i class="far fa-circle"></i>
        Understand your ranch's carrying capacity
      </li>
      <li>
        <i class="far fa-circle"></i>
        Keep grazing & animal records secure in one place
      </li>
      <li>
        <i class="far fa-circle"></i>
        Forecast forage and stocking in real time
      </li>
      <li>
        <i class="far fa-circle"></i>
        Manage your team from anywhere
      </li>
      <li>
        <i class="far fa-circle"></i>
        Compliance for NRCS, GAP, and other certifications
      </li>
    </ul>

    <div class="quote">
      <div class="quote-content">
        I check PastureMap constantly to see our progress against projected animal gains. Missing those gains is $60,000
        for just one of our herds. I can show my staff those numbers and fix problems before they get big.
        <div class="triangle"></div>
      </div>
      <div class="author">
        <div class="face"></div>
        <div class="name">Heidi Terrell</div>
        <div class="position">TERRELL RANCH</div>
      </div>
    </div>
  </div>
  <div class="right">
    <mat-card *ngIf="isLoading">
      <div class="padded-spinner">
        <mat-spinner diameter="90"></mat-spinner>
      </div>
    </mat-card>
    <mat-card *ngIf="!isLoading" class="padded-card">
      <mat-card-content>
        <form [formGroup]="signupForm" autocomplete="on" class="general-form" novalidate (ngSubmit)="signIn()">
          <mat-form-field appearance="outline" class="full-width">
            <label class="abs-top">Full Name</label>
            <input (keyup)="setRanchName()" type="text" required="required" matInput formControlName="fullName" />
            <mat-error *ngIf="hasError('fullName', 'required')">
              <i class="fal fa-exclamation-triangle"></i>
              Name is required
            </mat-error>
            <mat-error *ngIf="hasError('fullName', 'maxlength')">
              <i class="fal fa-exclamation-triangle"></i>
              Your name is toooooo long
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <label class="abs-top">Ranch Name</label>
            <input type="text" required="required" matInput formControlName="name" />
            <mat-error *ngIf="hasError('name', 'required')">
              <i class="fal fa-exclamation-triangle"></i>
              Ranch name is required
            </mat-error>
            <mat-error *ngIf="hasError('name', 'name')">
              <i class="fal fa-exclamation-triangle"></i>
              Ranch name is toooooo long
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <label class="abs-top">Email</label>
            <input type="email" required="required" matInput formControlName="email" />
            <mat-error *ngIf="hasError('email', 'required')">
              <i class="fal fa-exclamation-triangle"></i>
              Email is required
            </mat-error>
            <mat-error *ngIf="hasError('email', 'email')">
              <i class="fal fa-exclamation-triangle"></i>
              Email address contain errors
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <label class="abs-top">Password</label>
            <input
              [type]="showPassword ? 'text' : 'password'"
              required="required"
              class="password-input"
              matInput
              formControlName="password"
            />
            <button
              type="button"
              class="toggle-password"
              mat-icon-button
              matSuffix
              (click)="showPassword = !showPassword"
            >
              <mat-icon *ngIf="showPassword">visibility_off</mat-icon>
              <mat-icon *ngIf="!showPassword">visibility</mat-icon>
            </button>
            <mat-hint *ngIf="!signupForm.controls.password.touched">At least eight characters long</mat-hint>
            <mat-error *ngIf="hasError('password', 'required')">
              <i class="fal fa-exclamation-triangle"></i>
              Password is required
            </mat-error>
            <mat-error *ngIf="hasError('password', 'passwordStrength')">
              <i class="fal fa-exclamation-triangle"></i>
              {{ signupForm.get('password').errors['passwordStrength'] }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <label class="abs-top">Phone Number</label>
            <input type="text" required="required" matInput formControlName="phone" />
            <mat-error *ngIf="hasError('phone', 'required')">
              <i class="fal fa-exclamation-triangle"></i>
              Phone number is required
            </mat-error>
            <mat-error *ngIf="hasError('phone', 'name')">
              <i class="fal fa-exclamation-triangle"></i>
              Phone name is toooooo long
            </mat-error>
          </mat-form-field>

          <div class="full-width" [class.validationError]="hasError('terms', 'required')">
            <mat-checkbox formControlName="terms" required="required">
              <div class="terms-full">
                By clicking "Start Free Trial," you agree to PastureMap's
                <a target="_blank" href="https://grassrootscarbon.com/pasture-map/terms-of-service/">
                  Terms of Service
                </a>
                and consent to receive text messages regarding your inquiry. You can opt out by replying "STOP" or get
                more information by replying "HELP." Message frequency varies, and standard message and data rates may
                apply. Please review our
                <a target="_blank" href="https://grassrootscarbon.com/pasture-map/privacy-policy/">Privacy Policy</a>
                to understand how your data is used.
              </div>
            </mat-checkbox>
            <mat-error class="center-text" *ngIf="signupForm.get('terms').touched && hasError('terms', 'required')">
              <small>
                <i class="fal fa-exclamation-triangle"></i>
                You need to agree to the terms to continue
              </small>
            </mat-error>
          </div>
          <div class="full-width">
            <button class="big-wide-button sign-in" type="submit" mat-raised-button color="primary">
              Start Free Trial
            </button>
          </div>
        </form>
        <div class="sub-action">
          Already have an account?
          <a class="login" routerLink="/login" routerLinkActive="active">Log In</a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<!--<re-captcha-->
<!--  #captchaRef="reCaptcha"-->
<!--  siteKey="6Lcfa-IZAAAAADpFGwh-WdXYpC8jHJtWnsrCRf0E"-->
<!--  size="invisible"-->
<!--  (resolved)="$event && signIn()"-->
<!--&gt;</re-captcha>-->
