import { createAction, props } from '@ngrx/store';
import { GrazeEvent } from 'src/app/shared/models/graze-event.model';
import { Infrastructure } from 'src/app/shared/models/inventory.interface';
import { Paddock, SingleHerdPaddockHistory } from 'src/app/shared/models/paddock.interface';
import { Ranch } from 'src/app/shared/models/ranch.interface';
import { Season } from 'src/app/shared/models/season.interface';

export enum Types {
  GetRanch = `[Ranch] Get ranch`,
  GetRanchSuccess = `[Ranch] Get ranch success`,
  GetRanchFailure = `[Ranch] Get ranch failure`,

  GetSeasons = `[Ranch] Get seasons`,
  GetSeasonsSuccess = `[Ranch] Get seasons success`,
  GetSeasonsFailure = `[Ranch] Get seasons failure`,

  GetInfrastructures = `[Ranch] Get infrastructures`,
  GetInfrastructuresSuccess = `[Ranch] Get infrastructures success`,
  GetInfrastructuresFailure = `[Ranch] Get infrastructures failure`,

  GetPaddocks = `[Ranch] Get paddocks`,
  GetPaddocksSuccess = `[Ranch] Get paddocks success`,
  GetPaddocksFailure = `[Ranch] Get paddocks failure`,

  GrazeEventsCalculated = `[Ranch] Graze events calculated`,
}

export const GetRanch = createAction(Types.GetRanch);
export const GetRanchSuccess = createAction(Types.GetRanchSuccess, props<{ ranch: Ranch }>());
export const GetRanchFailure = createAction(Types.GetRanchFailure);

export const GetPaddocks = createAction(Types.GetPaddocks);
export const GetPaddocksSuccess = createAction(Types.GetPaddocksSuccess, props<{ paddocks: Array<Paddock> }>());
export const GetPaddocksFailure = createAction(Types.GetPaddocksFailure);

export const GetSeasons = createAction(Types.GetSeasons);
export const GetSeasonsSuccess = createAction(Types.GetSeasonsSuccess, props<{ seasons: Array<Season> }>());
export const GetSeasonsFailure = createAction(Types.GetSeasonsFailure);

export const GetInfrastructures = createAction(Types.GetInfrastructures);
export const GetInfrastructuresSuccess = createAction(
  Types.GetInfrastructuresSuccess,
  props<{ infrastructures: Array<Infrastructure> }>()
);
export const GetInfrastructuresFailure = createAction(Types.GetInfrastructuresFailure);

export const GrazeEventsCalculated = createAction(
  Types.GrazeEventsCalculated,
  props<{
    plannedEvents: GrazeEvent[];
    historicalEvents: GrazeEvent[];
    otherPaddockEvents: SingleHerdPaddockHistory[];
    earliestPossibleMovePerHerd: { [id: string]: number };
  }>()
);
