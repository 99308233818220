import { Pipe, PipeTransform } from '@angular/core';
import { UnitPersistencyService } from './unit-persistency.service';

@Pipe({
  name: `metricToImperial`,
})
export class MetricToImperialPipe implements PipeTransform {
  constructor(private unitSerice: UnitPersistencyService) {}

  transform(value: number, unit: SupportedUnits = `lb`): number {
    const shouldTransform = this.unitSerice.unit !== `imperial`;

    if (!shouldTransform || !value || isNaN(Number(value))) {
      return value;
    }

    switch (unit) {
      case `lb`:
        return value * 2.20462262185;
      case `in`:
        return value * 0.393701;
      case `ac`:
        return value * 2.471053816;
      default:
        return value;
    }
  }
}

type SupportedUnits = `lb` | `in` | `ac`;
