import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { map, tap } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';

import { SettingsConfigService } from './settings-config.service';
import { Me, User, Admin } from '../models/user.interface';
import { Season } from '../models/season.interface';
import { RANCH_TOKEN, SEASON_TOKEN, ADMIN_TOKEN } from '../constants/cookie-names.const';

@Injectable({
  providedIn: `root`,
})
export class SettingsUserService {
  public seasonUUID: string;
  public ranchUUID: string;
  private ranchRole: string;

  constructor(
    private settings: SettingsConfigService,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    const seasonFromStorage = this.getSeasonIdFromStorage();
    if (!seasonFromStorage) {
      this.cookieService.delete(SEASON_TOKEN, `/`);
    } else {
      this.seasonUUID = seasonFromStorage;
    }
  }

  public getUnits(): string {
    const units = localStorage.getItem(`units`);
    if (units) {
      return units;
    }
    return `imperial`;
  }

  public getSeasons(): Observable<Season[]> {
    return this.ranchUUID
      ? this.http.get<Season[]>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/season`).pipe(
          map((seasons) =>
            seasons.sort((a, b) => {
              const dateA = a.end_date ? new Date(a.end_date).getTime() : new Date().getTime();
              const dateB = b.end_date ? new Date(b.end_date).getTime() : new Date().getTime();
              return dateB - dateA;
            })
          )
        )
      : of([]);
  }

  public getUsers(): Observable<Array<User>> {
    return this.http.get<Array<User>>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/user`);
  }

  public changeSeason(seasonUUID: string): Observable<any> {
    return this.http.get<any>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/season/${seasonUUID}`).pipe(
      tap((season) => {
        this.setSeasonUUID(season.uuid);
      })
    );
  }

  public exportPastures(ranchId?: string): Observable<any> {
    return this.http.get(`${this.settings.settings.API_V1}/ranch/${ranchId ?? this.ranchUUID}/kml`, {
      responseType: `blob`,
    });
  }

  public createRanch(user): Observable<any> {
    return this.http.post<any>(`${this.settings.settings.API_V1}/ranch`, user);
  }

  public getRanchUUID(): string {
    return this.ranchUUID ?? this.cookieService.get(RANCH_TOKEN);
  }

  public setRanchUUID(ranchUUID: string): void {
    this.cookieService.set(RANCH_TOKEN, ranchUUID, 600000, `/`, this.settings.settings.COOKIE_DOMAIN);
    this.ranchUUID = ranchUUID;
  }

  public changeRanch(ranchUUID: string): void {
    this.cookieService.delete(SEASON_TOKEN, `/`);
    this.setRanchUUID(ranchUUID);
    window.location.reload();
  }

  public checkRanchUUID(ranchUUID: string): string {
    const selectedRanch = this.cookieService.get(RANCH_TOKEN);
    if (!selectedRanch || selectedRanch === `undefined` || !this.meRanchIds().includes(selectedRanch)) {
      this.cookieService.set(RANCH_TOKEN, ranchUUID, 600000, `/`, this.settings.settings.COOKIE_DOMAIN);
      return ranchUUID;
    }
    if (selectedRanch !== ranchUUID) {
      return selectedRanch;
    }
    return ranchUUID;
  }

  public setRanchRole(role: string): void {
    this.ranchRole = role;
  }

  public getRanchRole(): string {
    return this.ranchRole;
  }

  public setSeasonUUID(seasonUUID: string): void {
    this.cookieService.set(SEASON_TOKEN, seasonUUID, 600000, `/`, this.settings.settings.COOKIE_DOMAIN);
    this.seasonUUID = seasonUUID;
  }

  public getSeasonIdFromStorage(): string {
    return this.cookieService.get(SEASON_TOKEN);
  }

  public getAdmin(): Admin {
    const masquerade: string = this.cookieService.get(ADMIN_TOKEN);
    return masquerade ? <Admin>jwtDecode(masquerade) : null;
  }

  private meRanchIds(): Array<string> {
    const me: Me = JSON.parse(localStorage.getItem(`me`));

    if (!me) {
      return [];
    }
    return me.ranches?.map((r) => r.ranch_uuid);
  }
}
