import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { CustomValidators } from 'src/app/shared/form-validators/custom-validators.class';
import { AlertService } from '../../shared/alert/alert.service';
import { AuthService } from '../../shared/auth/auth.service';
import { PasswordValidator } from '../../shared/validators/password.validator';
import { EventTrackerService } from 'src/app/shared/services/event-tracker.service';
import { HashingService } from '../../shared/helpers/hashing.service';

@Component({
  selector: `pasture-signup`,
  templateUrl: `./signup.component.html`,
  styleUrls: [`./signup.component.scss`],
})
export class SignupComponent implements OnInit {
  public isLoading: boolean;
  public signupForm: FormGroup;
  public showPassword: boolean;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private readonly hashingService: HashingService,
    private router: Router,
    private eventTracker: EventTrackerService
  ) {
    this.isLoading = false;
    this.showPassword = false;
    if (this.authService.isLoggedIn()) {
      this.router.navigate([`/onboarding/step-1`]);
    }
  }

  ngOnInit(): void {
    this.signupForm = new FormGroup({
      fullName: new FormControl(``, [Validators.required, Validators.maxLength(100)]),
      name: new FormControl(``, [Validators.required, Validators.maxLength(100)]),
      phone: new FormControl(``, [Validators.required, Validators.maxLength(100)]),
      email: new FormControl(``, [Validators.required, CustomValidators.email]),
      password: new FormControl(``, [Validators.required, PasswordValidator]),
      terms: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  public async signIn(): Promise<void> {
    this.signupForm.markAllAsTouched();
    if (this.signupForm.valid) {
      this.eventTracker.push({
        event: `sign_up`,
        cp_product_name: `PastureMap`,
        cp_user_email: await this.hashingService.sha256(this.signupForm.get(`email`).value),
      });
      const formValues = this.signupForm.getRawValue();
      const fullName = this.signupForm.get(`fullName`).value;
      const user = {
        email: formValues.email,
        first_name: fullName.split(` `)[0],
        full_name: formValues.fullName,
        last_name: fullName.split(` `)[1] ? fullName.split(` `)[1] : fullName,
        name: formValues.name,
        password: formValues.password,
        phone_number: formValues.phone,
      };
      this.isLoading = true;
      this.authService.registerAndLogin(user).subscribe({
        next: () => {
          this.alertService.showSuccess(`You are ready to use PastureMap`, `Hello ${formValues.fullName}!`);
          this.router.navigate([`/onboarding/step-1`]);
        },
        error: (errResponse: any) => {
          this.isLoading = false;
          if (typeof errResponse.error.details !== `object`) {
            this.alertService.showError(errResponse.error.details, ``);
          } else {
            for (const [key, value] of Object.entries(errResponse.error.details)) {
              this.alertService.showError(value, key);
            }
          }
        },
      });
    }
  }

  public setRanchName(): void {
    const value = this.signupForm.get(`fullName`).value;
    if (value && !this.signupForm.controls.name.touched) {
      const ranchName = value.split(` `)[0];
      this.signupForm.patchValue({
        name: `${ranchName}'s Ranch`,
      });
    }
  }

  public hasError = (controlName: string, errorName: string): boolean => {
    return this.signupForm.controls[controlName].hasError(errorName);
  };
}
