import { Injectable } from '@angular/core';
import { Paddock } from 'src/app/shared/models/paddock.interface';
import { SettingsConfigService } from 'src/app/shared/settings/settings-config.service';

// Code importent from pasturemap-web
@Injectable({ providedIn: `root` })
export class PaddockGeometryParser {
  private readonly MAPBOX_SATELLITE_BASE_URL = `https://api.mapbox.com/styles/v1/pasturemapnew`;
  private readonly MAPBOX_STYLE_ID = `cllxiffmo00hu01r75nbjcnt4`;
  private readonly defaultHeight = 200;
  private readonly defaultWidth = 200;

  constructor(private settings: SettingsConfigService) {}

  public paddockImageUrl(paddock: Paddock, parent: Paddock, height?: number, width?: number): string {
    const paddockGeometry = structuredClone(paddock.coordinates);
    this.roundGeometryCoordinates(paddockGeometry, 6);
    let paddockGeoJSONFeature = {
      type: `Feature`,
      geometry: paddockGeometry,
      properties: {
        stroke: `#EEEEEE`,
        'stroke-width': 2,
        'stroke-opacity': 0.75,
        fill: `#00B16A`,
        'fill-opacity': 0.6,
      },
    };

    let tolerance;
    tolerance = 0.0001;
    let geoJSONFeatureString = JSON.stringify(paddockGeoJSONFeature);
    while (geoJSONFeatureString.length > 1700) {
      // max allowed by MapBox
      paddockGeoJSONFeature = this.simplifyGeoJSONFeature(paddockGeoJSONFeature, tolerance);
      geoJSONFeatureString = JSON.stringify(paddockGeoJSONFeature);
      tolerance = tolerance * 2;
      if (tolerance > 10) {
        return;
      }
    }

    let parentGeoJSONFeature;
    if (parent) {
      const parentGeometry = structuredClone(parent.coordinates);
      this.roundGeometryCoordinates(parentGeometry, 6);

      parentGeoJSONFeature = {
        type: `Feature`,
        geometry: parentGeometry,
        properties: {
          stroke: `#EEEEEE`,
          'stroke-width': 2,
          'stroke-opacity': 0.75,
          'fill-opacity': 0.75,
        },
      };
      tolerance = 0.0001;
      geoJSONFeatureString = JSON.stringify(parentGeoJSONFeature);
      while (geoJSONFeatureString.length > 1700) {
        // max allowed by MapBox
        parentGeoJSONFeature = this.simplifyGeoJSONFeature(parentGeoJSONFeature, tolerance);
        geoJSONFeatureString = JSON.stringify(parentGeoJSONFeature);
        tolerance = tolerance * 2;
        if (tolerance > 10) {
          return;
        }
      }
    }
    const geoJSONFeature = !parentGeoJSONFeature
      ? paddockGeoJSONFeature
      : {
          type: `FeatureCollection`,
          features: [parentGeoJSONFeature, paddockGeoJSONFeature],
        };
    geoJSONFeatureString = JSON.stringify(geoJSONFeature);

    return (
      this.MAPBOX_SATELLITE_BASE_URL +
      `/` +
      this.MAPBOX_STYLE_ID +
      `/static/geojson(` +
      encodeURIComponent(geoJSONFeatureString) +
      `)/auto/` +
      (width ?? this.defaultWidth) +
      `x` +
      (height ?? this.defaultHeight) +
      `?access_token=` +
      this.settings.settings.MAPBOX_KEY
    );
  }

  private roundGeometryCoordinates(geometry, dp: number): void {
    for (let linearRingI = 0; linearRingI < geometry.coordinates.length; linearRingI++) {
      const linearRing = geometry.coordinates[linearRingI];
      for (let pointI = 0; pointI < linearRing.length; pointI++) {
        linearRing[pointI][0] = Math.round(linearRing[pointI][0] * 10 ** dp) / 10 ** dp;
        linearRing[pointI][1] = Math.round(linearRing[pointI][1] * 10 ** dp) / 10 ** dp;
      }
    }
  }

  private simplifyRadialDist(points, sqTolerance): any {
    let prevPoint = points[0];
    const newPoints = [prevPoint];
    let point;

    for (let i = 1, len = points.length; i < len; i++) {
      point = points[i];

      if (this.getSqDist(point, prevPoint) > sqTolerance) {
        newPoints.push(point);
        prevPoint = point;
      }
    }

    if (prevPoint !== point) newPoints.push(point);

    return newPoints;
  }

  private getSqDist(p1, p2): number {
    const dx = p1[0] - p2[0],
      dy = p1[1] - p2[1];

    return dx * dx + dy * dy;
  }

  private simplifyGeoJSONFeature(geoJSONObj, tolerance): any {
    for (let i = 0; i < geoJSONObj.geometry.coordinates.length; i++) {
      const simplifiedLinearRing = this.simplify(geoJSONObj.geometry.coordinates[i], tolerance);
      if (simplifiedLinearRing.length >= 4) {
        geoJSONObj.geometry.coordinates[i] = simplifiedLinearRing;
      }
    }
    return geoJSONObj;
  }

  private simplify(points, tolerance): any {
    if (points.length <= 2) {
      return points;
    }

    const sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;

    points = this.simplifyRadialDist(points, sqTolerance);
    points = this.simplifyDouglasPeucker(points, sqTolerance);

    return points;
  }

  private simplifyDouglasPeucker(points, sqTolerance): any {
    const last = points.length - 1;

    const simplified = [points[0]];
    this.simplifyDPStep(points, 0, last, sqTolerance, simplified);
    simplified.push(points[last]);

    return simplified;
  }

  private simplifyDPStep(points, first, last, sqTolerance, simplified): void {
    let maxSqDist = sqTolerance,
      index;

    for (let i = first + 1; i < last; i++) {
      const sqDist = this.getSqSegDist(points[i], points[first], points[last]);

      if (sqDist > maxSqDist) {
        index = i;
        maxSqDist = sqDist;
      }
    }

    if (maxSqDist > sqTolerance) {
      if (index - first > 1) this.simplifyDPStep(points, first, index, sqTolerance, simplified);
      simplified.push(points[index]);
      if (last - index > 1) this.simplifyDPStep(points, index, last, sqTolerance, simplified);
    }
  }

  private getSqSegDist(p, p1, p2): number {
    let x = p1[0],
      y = p1[1],
      dx = p2[0] - x,
      dy = p2[1] - y;

    if (dx !== 0 || dy !== 0) {
      const t = ((p[0] - x) * dx + (p[1] - y) * dy) / (dx * dx + dy * dy);

      if (t > 1) {
        x = p2[0];
        y = p2[1];
      } else if (t > 0) {
        x += dx * t;
        y += dy * t;
      }
    }

    dx = p[0] - x;
    dy = p[1] - y;

    return dx * dx + dy * dy;
  }
}
