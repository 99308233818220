import { createAction, props } from '@ngrx/store';
import { GrazeEvent } from 'src/app/shared/models/graze-event.model';
import { Infrastructure } from 'src/app/shared/models/inventory.interface';
import { Paddock, PaddockHistory, SingleHerdPaddockHistory } from 'src/app/shared/models/paddock.interface';
import { Ranch } from 'src/app/shared/models/ranch.interface';
import { Season } from 'src/app/shared/models/season.interface';

export enum Types {
  GetRanch = `[Ranch] Get ranch`,
  GetRanchSuccess = `[Ranch] Get ranch success`,
  GetRanchFailure = `[Ranch] Get ranch failure`,

  GetSeasons = `[Ranch] Get seasons`,
  GetSeasonsSuccess = `[Ranch] Get seasons success`,
  GetSeasonsFailure = `[Ranch] Get seasons failure`,

  GetInfrastructures = `[Ranch] Get infrastructures`,
  GetInfrastructuresSuccess = `[Ranch] Get infrastructures success`,
  GetInfrastructuresFailure = `[Ranch] Get infrastructures failure`,

  AddInfrastructures = `[Ranch] Add infrastructures`,
  UpdateInfrastructures = `[Ranch] Update infrastructures`,
  DeleteInfrastructures = `[Ranch] Delete infrastructures`,

  GetPaddocks = `[Ranch] Get paddocks`,
  GetPaddocksSuccess = `[Ranch] Get paddocks success`,
  GetPaddocksFailure = `[Ranch] Get paddocks failure`,

  UpdatePaddock = `[Ranch] Update paddock`,
  UpdatePaddockSuccess = `[Ranch] Update paddock success`,
  UpdatePaddockFailure = `[Ranch] Update paddock failure`,

  DeletePaddock = `[Ranch] Delete paddock`,
  DeletePaddockSuccess = `[Ranch] Delete paddock success`,
  DeletePaddockFailure = `[Ranch] Delete paddock failure`,

  GrazeEventsCalculated = `[Ranch] Graze events calculated`,
  UpdatePaddocksHistory = `[Ranch] Update paddocks history`,
}

export const GetRanch = createAction(Types.GetRanch);
export const GetRanchSuccess = createAction(Types.GetRanchSuccess, props<{ ranch: Ranch }>());
export const GetRanchFailure = createAction(Types.GetRanchFailure);

export const GetPaddocks = createAction(Types.GetPaddocks);
export const GetPaddocksSuccess = createAction(Types.GetPaddocksSuccess, props<{ paddocks: Array<Paddock> }>());
export const GetPaddocksFailure = createAction(Types.GetPaddocksFailure);

export const UpdatePaddock = createAction(
  Types.UpdatePaddock,
  props<{ paddock: Partial<Paddock> & { uuid: string } }>()
);
export const UpdatePaddockSuccess = createAction(Types.UpdatePaddockSuccess, props<{ paddock: Paddock }>());
export const UpdatePaddockFailure = createAction(Types.UpdatePaddockFailure);

export const DeletePaddock = createAction(Types.DeletePaddock, props<{ paddockUuid: string }>());
export const DeletePaddockSuccess = createAction(Types.DeletePaddockSuccess, props<{ paddockUuid: string }>());
export const DeletePaddockFailure = createAction(Types.DeletePaddockFailure);

export const GetSeasons = createAction(Types.GetSeasons);
export const GetSeasonsSuccess = createAction(Types.GetSeasonsSuccess, props<{ seasons: Array<Season> }>());
export const GetSeasonsFailure = createAction(Types.GetSeasonsFailure);

export const GetInfrastructures = createAction(Types.GetInfrastructures);
export const GetInfrastructuresSuccess = createAction(
  Types.GetInfrastructuresSuccess,
  props<{ infrastructures: Array<Infrastructure> }>()
);
export const GetInfrastructuresFailure = createAction(Types.GetInfrastructuresFailure);

export const AddInfrastructures = createAction(
  Types.AddInfrastructures,
  props<{ infrastructures: Infrastructure[] }>()
);
export const UpdateInfrastructures = createAction(
  Types.UpdateInfrastructures,
  props<{ infrastructures: Infrastructure[] }>()
);
export const DeleteInfrastructures = createAction(
  Types.DeleteInfrastructures,
  props<{ infrastructureIds: string[] }>()
);

export const GrazeEventsCalculated = createAction(
  Types.GrazeEventsCalculated,
  props<{
    plannedEvents: GrazeEvent[];
    historicalEvents: GrazeEvent[];
    otherPaddockEvents: SingleHerdPaddockHistory[];
    earliestPossibleMovePerHerd: { [id: string]: number };
  }>()
);
export const UpdatePaddocksHistory = createAction(
  Types.UpdatePaddocksHistory,
  props<{ events?: PaddockHistory[]; eventsToDelete?: PaddockHistory[] }>()
);
