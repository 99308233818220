import { Map } from 'ol';
import { Interaction } from 'ol/interaction';
import { BehaviorSubject } from 'rxjs';

export class InteractionsService {
  private _interactionActive = new BehaviorSubject<boolean>(false);
  public interactionActive$ = this._interactionActive.asObservable();

  public get interactionActive(): boolean {
    return this._interactionActive.value;
  }
  private set interactionActive(ia: boolean) {
    this._interactionActive.next(ia);
  }

  private activeInteractions: Interaction[] = [];

  constructor(private map: Map) {}

  public addInteraction(interaction: Interaction): void {
    this.interactionActive = true;
    this.map.addInteraction(interaction);
    this.activeInteractions.push(interaction);
  }

  public removeInteraction(interaction: Interaction): void {
    if (!interaction) {
      return;
    }
    interaction.dispose();
    this.map.removeInteraction(interaction);
    this.activeInteractions = this.activeInteractions.filter((i) => i !== interaction);
    this.interactionActive = this.activeInteractions.length > 0;
  }

  public removeAllInteractions(): void {
    this.activeInteractions.forEach((interaction) => {
      this.removeInteraction(interaction);
    });
  }
}
