import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/shared/form-validators/custom-validators.class';
import { AlertService } from '../../shared/alert/alert.service';
import { AuthService } from '../../shared/auth/auth.service';
import { PasswordValidator } from '../../shared/validators/password.validator';

@Component({
  selector: `pasture-signup-mobile`,
  templateUrl: `./signup-mobile.component.html`,
  styleUrls: [`./signup-mobile.component.scss`],
})
export class SignupMobileComponent implements OnInit {
  public isLoading: boolean;
  public signupForm: FormGroup;
  public showPassword: boolean;

  constructor(private alertService: AlertService, private authService: AuthService, private router: Router) {
    this.isLoading = false;
    this.showPassword = false;
    if (this.authService.isLoggedIn()) {
      this.router.navigate([`/settings/account`]);
    }
  }

  ngOnInit(): void {
    this.signupForm = new FormGroup({
      fullName: new FormControl(``, [Validators.required, Validators.maxLength(100)]),
      name: new FormControl(``, [Validators.required, Validators.maxLength(100)]),
      phone: new FormControl(``, [Validators.required, Validators.maxLength(100)]),
      email: new FormControl(``, [Validators.required, CustomValidators.email]),
      password: new FormControl(``, [Validators.required, PasswordValidator]),
    });
  }

  public signIn(): void {
    if (this.signupForm.valid) {
      const formValues = this.signupForm.getRawValue();
      const fullName = this.signupForm.get(`fullName`).value;
      const user = {
        email: formValues.email,
        first_name: fullName.split(` `)[0],
        full_name: formValues.fullName,
        last_name: fullName.split(` `)[1] ? fullName.split(` `)[1] : fullName,
        name: formValues.name,
        password: formValues.password,
        phone_number: formValues.phone,
      };
      this.isLoading = true;
      this.authService.registerAndLogin(user).subscribe({
        next: () => {
          this.alertService.showSuccess(`You are ready to use PastureMap`, `Hello ${formValues.fullName}!`);
          this.router.navigate([`/settings/account`]);
        },
        error: (errResponse: any) => {
          this.isLoading = false;
          if (typeof errResponse.error.details !== `object`) {
            this.alertService.showError(errResponse.error.details, ``);
          } else {
            for (const [key, value] of Object.entries(errResponse.error.details)) {
              this.alertService.showError(value, key);
            }
          }
        },
      });
    }
  }

  public setRanchName(): void {
    const value = this.signupForm.get(`fullName`).value;
    if (value && !this.signupForm.controls.name.touched) {
      const ranchName = value.split(` `)[0];
      this.signupForm.patchValue({
        name: `${ranchName}'s Ranch`,
      });
    }
  }

  public hasError = (controlName: string, errorName: string): boolean => {
    return this.signupForm.controls[controlName].hasError(errorName);
  };
}
