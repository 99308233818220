<div *ngIf="!hideSeasonTag && isPastSeason" class="season-tag">
  <span>Past Season</span>
</div>
<button
  id="open-menu-button"
  mat-button
  #t="matMenuTrigger"
  [matMenuTriggerFor]="mainMenu"
  [class.white]="whiteUserButton"
>
  {{ user?.first_name }} {{ user?.last_name }}
  <span *ngIf="masquerading">(masquerading)</span>
  <mat-icon iconPositionEnd class="caret" [class.rotated]="t.menuOpen" aria-hidden="false">
    keyboard_arrow_down
  </mat-icon>
</button>
<mat-menu #mainMenu="matMenu" xPosition="before">
  <ng-container *ngFor="let link of navLinks">
    <a
      routerLinkActive
      #rla="routerLinkActive"
      [disabled]="rla.isActive"
      [routerLink]="link.link"
      mat-menu-item
      *ngIf="(!link.ownerRoleRequired || userRole === 'owner') && (!link.ranchRequired || user?.ranches.length)"
    >
      <mat-icon>{{ link.icon }}</mat-icon>
      <span>{{ link.label }}</span>
    </a>
  </ng-container>

  <ng-container *ngIf="user?.ranches.length > 1">
    <button mat-menu-item [matMenuTriggerFor]="ranches">
      <mat-icon>change_circle</mat-icon>
      <span>Switch Ranch</span>
    </button>
  </ng-container>

  <ng-container *ngIf="seasons?.length > 1">
    <button mat-menu-item [matMenuTriggerFor]="seasonsList">
      <mat-icon>browse_gallery</mat-icon>
      <span>Switch Season</span>
    </button>
  </ng-container>

  <a target="_blank" href="http://help.pasturemap.com/web-user-guide" mat-menu-item>
    <mat-icon>support</mat-icon>
    <span>Help</span>
  </a>

  <button *ngIf="masquerading" (click)="endMasqerade()" mat-menu-item>
    <mat-icon>remove_moderator</mat-icon>
    <span>End Masquerade</span>
  </button>

  <button (click)="logOut()" mat-menu-item>
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>

<mat-menu #ranches="matMenu">
  <ng-container *ngFor="let ranch of user?.ranches">
    <button (click)="changeRanch(ranch.ranch_uuid)" mat-menu-item [disabled]="ranch.ranch_uuid === currentRanchUUID">
      <span>
        {{ ranch.name }}
        <small style="opacity: 0.6" class="styled">({{ ranch.role }})</small>
      </span>
    </button>
  </ng-container>
</mat-menu>

<mat-menu #seasonsList="matMenu">
  <ng-container *ngFor="let season of seasons">
    <button (click)="changeSeason(season.uuid)" mat-menu-item [disabled]="season.uuid === currentSeasonUUID">
      <span *ngIf="season.start_date">{{ season.start_date | date: 'longDate' }}</span>
      <span *ngIf="!season.start_date">Not started</span>
      -
      <span *ngIf="season.end_date">{{ season.end_date | date: 'longDate' }}</span>
      <span *ngIf="!season.end_date">Ongoing</span>
    </button>
  </ng-container>
</mat-menu>
