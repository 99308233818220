import { Pipe, PipeTransform } from '@angular/core';
import { UnitPersistencyService } from './unit-persistency.service';

@Pipe({
  name: `imperialToMetric`,
})
export class ImperialToMetricPipe implements PipeTransform {
  constructor(private unitSerice: UnitPersistencyService) {}

  transform(value: number, unit: SupportedUnits = `lb`): number {
    const shouldTransform = this.unitSerice.unit !== `imperial`;

    if (!shouldTransform || !value || isNaN(Number(value))) {
      return value;
    }

    switch (unit) {
      case `lb`:
        return value * 0.45359237;
      case `in`:
        return value * 2.54;
      case `ac`:
        return value * 0.404685642;
      default:
        return value;
    }
  }
}

type SupportedUnits = `lb` | `in` | `ac`;
