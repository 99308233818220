import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { SettingsUserService } from 'src/app/shared/settings/settings-user.service';
import { Season } from 'src/app/shared/models/season.interface';
import { selectMe } from 'src/app/store/user/user.selectors';
import { AuthService } from '../../../auth/auth.service';
import { Me, UserRole } from '../../../models/user.interface';
import { SEASON_TOKEN } from 'src/app/shared/consts/cookie-names.const';

@Component({
  selector: `pasture-user-menu`,
  templateUrl: `./user-menu.component.html`,
  styleUrls: [`./user-menu.component.scss`],
})
export class UserMenuComponent implements OnInit {
  @Input() whiteUserButton = false;
  @Input() linksOnly = false;
  @Input() hideSeasonTag = false;
  public navLinks = [
    {
      label: `Account`,
      link: `/settings/account`,
      icon: `manage_accounts`,
      ownerRoleRequired: false,
      index: 0,
    },
    {
      label: `Ranch`,
      link: `/settings/ranch`,
      icon: `grass`,
      ownerRoleRequired: false,
      ranchRequired: true,
      index: 1,
    },
    {
      label: `Team Members`,
      link: `/settings/team`,
      icon: `groups`,
      ownerRoleRequired: true,
      ranchRequired: true,
      index: 2,
    },
    {
      label: `Subscription`,
      link: `/settings/subscription`,
      icon: `payment`,
      ownerRoleRequired: true,
      ranchRequired: true,
      index: 3,
    },
  ];
  public currentRanchUUID = ``;

  public get currentSeasonUUID(): string {
    return this.sus.seasonUUID;
  }

  public get isPastSeason(): boolean {
    return !!this.seasons?.find((s) => s.uuid === this.currentSeasonUUID)?.end_date;
  }

  public user: Me;
  public userRole: UserRole;
  public seasons: Season[];
  public masquerading: boolean;

  constructor(
    private readonly authService: AuthService,
    private readonly sus: SettingsUserService,
    private readonly store: Store,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.masquerading = !!this.sus.getAdmin();
    this.store
      .select(selectMe)
      .pipe(
        filter((v) => !!v),
        first()
      )
      .subscribe((me) => {
        const firstRanch = me.ranches[0]?.ranch_uuid;
        this.currentRanchUUID = this.sus.checkRanchUUID(firstRanch);
        this.userRole = me.ranches.find((o) => o.ranch_uuid === this.currentRanchUUID)?.role;
        this.sus.setRanchUUID(this.currentRanchUUID);
        this.sus.setRanchRole(this.userRole);
        this.user = me;
      });
    this.sus.getSeasons().subscribe((seasons) => {
      this.seasons = seasons;
      if (seasons.length) {
        const seasonIdFromStorage = this.sus.getSeasonIdFromStorage();
        this.sus.setSeasonUUID(seasonIdFromStorage ? seasonIdFromStorage : this.seasons[0].uuid);
      }
    });
  }

  public logOut(): void {
    this.authService.logout();
  }

  public endMasqerade(): void {
    this.authService.endMasquerade();
  }

  public changeRanch(ranchUUID: string): void {
    this.cookieService.delete(SEASON_TOKEN, `/`);
    this.sus.setRanchUUID(ranchUUID);
    window.location.reload();
  }

  public changeSeason(uuid: string): void {
    this.sus.changeSeason(uuid).subscribe(() => window.location.reload());
  }
}
