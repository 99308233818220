import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function IsDateValidator(control: AbstractControl): ValidationErrors | null {
  const value: Date = control?.value;
  if (!value) {
    return null;
  }

  if (!(value instanceof Date)) {
    return { notADate: true };
  }

  return;
}

export function NoWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
  const isWhitespace = (control.value || ``).trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { required: `This field is required` };
}

export function DateValidator(control: AbstractControl): ValidationErrors | null {
  const value: Date = control?.value;
  if (!value) {
    return null;
  }

  if (value instanceof Date) {
    return null;
  }

  const date = new Date(value);

  if (!date?.getTime()) {
    return { notDateParsable: true };
  }

  return;
}

export function MinDateValidator(minDate: number | string | Date): ValidatorFn {
  const minDateInEpoch = new Date(minDate).valueOf();
  return (control: AbstractControl) => {
    const date: Date = control?.value;
    if (!date) {
      return null;
    }

    if (!(date instanceof Date)) {
      return null;
    }

    if (date.valueOf() < minDateInEpoch) {
      return { minDate: { min: minDateInEpoch, actual: date.valueOf() } };
    }
  };
}

export function MaxDateValidator(maxDate: number | string | Date): ValidatorFn {
  const maxDateInEpoch = new Date(maxDate).valueOf();
  return (control: AbstractControl) => {
    const date: Date = control?.value;
    if (!date) {
      return null;
    }

    if (!(date instanceof Date)) {
      return null;
    }

    if (date.valueOf() > maxDateInEpoch) {
      return { maxDate: { max: maxDateInEpoch, actual: date.valueOf() } };
    }
  };
}

export function DateRangeValidator(fromControlName: string, toControlName: string): ValidatorFn {
  return (group: FormGroup): ValidationErrors => {
    const fromControl = group.get(fromControlName);
    const toControl = group.get(toControlName);

    if (!fromControl?.value || !toControl?.value) {
      return null;
    }
    return new Date(fromControl.value) > new Date(toControl.value) ? { invalidDateRange: true } : null;
  };
}
