import { createSelector } from '@ngrx/store';
import { State } from './rainfall.state';
import { selectRanch } from '../ranch/ranch.selectors';
import { RootState } from '../..';

const selectRainfallState = (rootState: RootState): State => rootState.ranch.rainfallState;

export const selectRainfallData = createSelector(selectRainfallState, (state: State) => state.rainfall);
export const selectRainfallDataLoading = createSelector(selectRainfallState, (state: State) => state.rainfallLoading);
export const selectRainfallStations = createSelector(selectRainfallState, (state: State) => state.rainfallStations);
export const selectRainfallStationsLoading = createSelector(
  selectRainfallState,
  (state: State) => state.rainfallStationsLoading
);

export const selectCurrentRainfallStation = createSelector(selectRanch, (ranch) => ranch?.selected_rainfall_source);
export const selectRainfallStartDate = createSelector(selectRanch, (ranch) => ranch?.rainfall_start_date);
