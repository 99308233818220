<div class="background"></div>
<div class="main">
  <div class="overflow-container">
    <div class="container">
      <div class="logo-container">
        <img class="logo" src="/assets/img/logo_white.svg" alt="PastureMap" />
        <mat-divider vertical="true"></mat-divider>
        <img class="logo" src="/assets/img/grc_logo.svg" alt="Grc" />
      </div>

      <mat-card class="padded-card">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-12 col-md-7 column">
              <div class="title">
                <mat-radio-group
                  formControlName="title"
                  [class.invalid-radio]="submitted && form.controls.title.invalid"
                >
                  <mat-radio-button value="mr">Mr.</mat-radio-button>
                  <mat-radio-button value="ms">Ms.</mat-radio-button>
                  <mat-radio-button value="mrs">Mrs.</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && form.controls.title.invalid">
                  {{ errorGen.getErrorMessage(form.controls.title) }}
                </mat-error>
              </div>
              <div class="row inner-container">
                <div class="col-12 col-sm-6">
                  <mat-form-field appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput type="text" formControlName="firstName" />
                    <mat-error *ngIf="form.controls.firstName.invalid">
                      {{ errorGen.getErrorMessage(form.controls.firstName) }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                  <mat-form-field appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text" formControlName="lastName" />
                    <mat-error *ngIf="form.controls.lastName.invalid">
                      {{ errorGen.getErrorMessage(form.controls.lastName) }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <mat-form-field appearance="outline">
                <mat-label>Ranch Name</mat-label>
                <input matInput type="text" formControlName="ranchName" />
                <mat-error *ngIf="form.controls.ranchName.invalid">
                  {{ errorGen.getErrorMessage(form.controls.ranchName) }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput [type]="visiblePassword ? 'text' : 'password'" formControlName="password" />
                <button mat-icon-button matSuffix (click)="visiblePassword = !visiblePassword">
                  <mat-icon>{{ visiblePassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                </button>
                <mat-error *ngIf="form.controls.password.invalid">
                  {{ errorGen.getErrorMessage(form.controls.password) }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="text" formControlName="email" />
                <mat-error *ngIf="form.controls.email.invalid">
                  {{ errorGen.getErrorMessage(form.controls.email) }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Phone Number</mat-label>
                <span matPrefix>&nbsp;+1&nbsp;</span>
                <input matInput type="tel" formControlName="phone" placeholder="(555)555-1234" mask="(000)000-0000" />
                <mat-error *ngIf="form.controls.phone.invalid">
                  {{ errorGen.getErrorMessage(form.controls.phone) }}
                </mat-error>
              </mat-form-field>
              <div class="row inner-container">
                <div class="col-12 col-sm-6">
                  <mat-form-field appearance="outline">
                    <mat-label>State</mat-label>
                    <input
                      #stateInput
                      type="text"
                      matInput
                      formControlName="state"
                      [matAutocomplete]="states"
                      (input)="filterStates()"
                      (focus)="filterStates()"
                    />
                    <mat-autocomplete requireSelection autoActiveFirstOption #states="matAutocomplete">
                      @for (state of filteredStates; track state) {
                        <mat-option [value]="state">
                          {{ state }}
                        </mat-option>
                      }
                    </mat-autocomplete>
                    <mat-error *ngIf="form.controls.state.invalid">
                      {{ errorGen.getErrorMessage(form.controls.state) }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-sm-6">
                  <mat-form-field appearance="outline">
                    <mat-label>Zip Code</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="zip"
                      mask="00000||00000-0000"
                      [dropSpecialCharacters]="false"
                    />
                    <mat-error *ngIf="form.controls.zip.invalid">
                      {{ errorGen.getErrorMessage(form.controls.zip) }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5 column">
              <div class="ranch-type">
                <label>What type of ranch are you?</label>
                <mat-radio-group
                  formControlName="ranchType"
                  [class.invalid-radio]="submitted && form.controls.ranchType.invalid"
                >
                  <mat-radio-button value="cow">Cow-calf</mat-radio-button>
                  <mat-radio-button value="contract">Contract Grazing</mat-radio-button>
                  <mat-radio-button value="stocker">Stocker</mat-radio-button>
                  <mat-radio-button value="direct">Direct Market</mat-radio-button>
                  <mat-radio-button value="dairy">Dairy</mat-radio-button>
                  <mat-radio-button value="seed">Seed stock</mat-radio-button>
                  <mat-radio-button value="other">Other</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="submitted && form.controls.ranchType.invalid">
                  {{ errorGen.getErrorMessage(form.controls.ranchType) }}
                </mat-error>
              </div>
              <div class="members-count">
                <label>How many members will need access to PastureMap aside from yourself?</label>
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="membersCount" min="0" />
                  <mat-error *ngIf="form.controls.membersCount.invalid">
                    {{ errorGen.getErrorMessage(form.controls.membersCount) }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="communication">
                <label>What is your preferred form of communication?</label>
                <section [formGroup]="form.controls.preferedCommunication">
                  <mat-checkbox formControlName="phone">Phone Call</mat-checkbox>
                  <mat-checkbox formControlName="email">Email</mat-checkbox>
                  <mat-checkbox formControlName="video">Video Call</mat-checkbox>
                  <mat-checkbox formControlName="text">Text</mat-checkbox>
                </section>
              </div>
            </div>
          </div>

          <div class="top-action">
            By clicking Join, you are agreeing to PastureMap's
            <a target="_blank" href="https://grassrootscarbon.com/pasture-map/terms-of-service/">Terms of Service</a>
            and
            <a target="_blank" href="https://grassrootscarbon.com/pasture-map/privacy-policy/">Privacy Policy</a>
          </div>

          <div class="full-width">
            <button class="submit-button" mat-raised-button color="primary" (click)="submit()">Join</button>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
