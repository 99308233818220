import { SettingsModel, SETTINGS_TOKEN } from './settings.model';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: `root`,
})
export class SettingsConfigService {
  constructor(@Inject(SETTINGS_TOKEN) config: SettingsModel) {
    this.settings = config;
  }

  public settings: SettingsModel;

  getSettings(): any {
    return this.settings;
  }
}
