import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Store } from '@ngrx/store';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from './shared/alert/alert.service';
import { GetUnits } from './store/settings/settings.actions';
import { SettingsConfigService } from './shared/settings/settings-config.service';

@Component({
  selector: `pasture-app-root`,
  templateUrl: `./app.component.html`,
  styleUrls: [`./app.component.scss`],
})
export class AppComponent implements OnInit {
  public showMobileWarning = false;

  constructor(
    private readonly settings: SettingsConfigService,
    private alertService: AlertService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private store: Store,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.handleLegacyDevices();
    this.store.dispatch(GetUnits());
    this.registerCustomIcons();
  }

  private handleLegacyDevices(): void {
    if (this.deviceService.isMobile()) {
      this.showMobileWarning = !this.confirmedMobileView() && !this.routeAllowedOnMobile();
    } else if (this.deviceService.browser === `MS-Edge` || this.deviceService.browser === `IE`) {
      this.router.navigate([`/too-old`]);
    }
  }

  private routeAllowedOnMobile(): boolean {
    const pathname = window.location.pathname;
    const mobileRoutes = [`/mobile-join`, `/join`, `/password-reset`, `/cancel_delete`, `/subscribe`];
    if (mobileRoutes.includes(pathname)) {
      if (pathname === `/mobile-join`) {
        this.alertService.showInfo(`Welcome to PastureMap`, `Hello!`);
      } else if (pathname === `/join`) {
        this.alertService.showInfo(`You are trying to join pasture`, `Great!`);
      }
      return true;
    } else {
      return false;
    }
  }

  public confirmMobileUsage(): void {
    this.cookieService.set(`seenMobileAppModal`, `true`, 365, `/`, this.settings.settings.COOKIE_DOMAIN);
    this.showMobileWarning = false;
  }

  private confirmedMobileView(): boolean {
    const confirmed = this.cookieService.get(`seenMobileAppModal`);
    return confirmed === `true`;
  }

  private registerCustomIcons(): void {
    this.addIconToRegistry(`cow`);
    this.addIconToRegistry(`hay`);
    this.addIconToRegistry(`soil`);
    this.addIconToRegistry(`subdivide`);
    this.addIconToRegistry(`cattle_1`);
    this.addIconToRegistry(`horse_1`);
    this.addIconToRegistry(`donkey_1`);
    this.addIconToRegistry(`cattle_2`);
    this.addIconToRegistry(`sheep_1`);
    this.addIconToRegistry(`pig_1`);
    this.addIconToRegistry(`chicken_1`);
    this.addIconToRegistry(`bison_1`);
    this.addIconToRegistry(`export`);
    this.addIconToRegistry(`printer`);
    this.addIconToRegistry(`ruler`);
    this.addIconToRegistry(`bale`);
    this.addIconToRegistry(`gate`);
    this.addIconToRegistry(`water`);
    this.addIconToRegistry(`rain`);
    this.addIconToRegistry(`other`);
    this.addIconToRegistry(`shovel`);
    this.addIconToRegistry(`grass`);
    this.addIconToRegistry(`icon_excel`);
  }

  private addIconToRegistry(name: string): void {
    const iconFile = `assets/icons/${name}.svg`;
    this.iconRegistry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(iconFile));
  }
}
