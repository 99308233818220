import { createReducer, on } from '@ngrx/store';
import { initialState, State } from './user.state';
import * as UserActions from './user.actions';

export const userReducer = createReducer<State>(
  initialState,
  on(
    UserActions.GetMeSuccess,
    (state, { me }): State => ({
      ...state,
      me,
    })
  ),
  on(
    UserActions.GetSubSuccess,
    (state, { sub }): State => ({
      ...state,
      sub,
      subLoaded: true,
    })
  ),
  on(
    UserActions.GetSub,
    (state): State => ({
      ...state,
      subLoaded: false,
    })
  ),
  on(
    UserActions.GetSubFailure,
    (state): State => ({
      ...state,
      subLoaded: true,
    })
  ),
  on(
    UserActions.GetTeamSuccess,
    (state, { team }): State => ({
      ...state,
      ranchTeam: team,
    })
  )
);
