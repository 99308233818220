@if (!photoSrc) {
  @if (!readonly) {
    <div class="upload-field" pastureDragDrop (fileDropped)="setImage($event[0])">
      <div class="text">
        Drop your photo here or...
        <button mat-raised-button (click)="endPhotoInput.click()" color="primary">Browse</button>
      </div>
      <input
        hidden
        type="file"
        #endPhotoInput
        accept=".png, .jpg, .jpeg, .gif, .svg, .webp"
        (change)="setImage($any($event).target.files[0])"
      />
    </div>
  } @else {
    <div class="upload-field readonly">
      <mat-icon color="primary" inline>no_photography</mat-icon>
    </div>
  }
} @else {
  <div class="preview">
    <div class="preview-actions">
      <button mat-mini-fab matTooltip="View" color="primary" (click)="seePhoto()">
        <mat-icon>visibility</mat-icon>
      </button>
      @if (!readonly) {
        <button mat-mini-fab matTooltip="Remove" color="warn" (click)="deletePhoto()">
          <mat-icon>delete</mat-icon>
        </button>
      }
    </div>
    <img [src]="photoSrc" />
  </div>
}
