import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../shared/alert/alert.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasqueradeModel } from './masquerade.model';
import { AuthService } from '../../shared/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginModel } from '../login/login.model';
import { SettingsConfigService } from '../../shared/settings/settings-config.service';
import { CustomValidators } from 'src/app/shared/form-validators/custom-validators.class';

@Component({
  selector: `pasture-masquerade`,
  templateUrl: `./masquerade.component.html`,
})
export class MasqueradeComponent implements OnInit {
  public isLoading: boolean;
  public loginForm: FormGroup;
  private returnUrl: string;
  private adminLogin: LoginModel = {
    email: ``,
    password: ``,
  };
  private masqueradeLogin: any = {
    email: ``,
    admin_password: ``,
  };

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private router: Router,
    private readonly settings: SettingsConfigService,
    private route: ActivatedRoute
  ) {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      admin_email: new FormControl(``, [Validators.required, CustomValidators.email]),
      user_email: new FormControl(``, [Validators.required, CustomValidators.email]),
      admin_password: new FormControl(``, [Validators.required, Validators.maxLength(100)]),
    });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || `/onboarding/step-1`;
  }

  public masquerade(admin: MasqueradeModel): void {
    if (this.loginForm.valid) {
      this.adminLogin.email = admin.admin_email;
      this.masqueradeLogin.email = admin.user_email;
      this.adminLogin.password = this.masqueradeLogin.admin_password = admin.admin_password;
      this.isLoading = true;
      this.authService.login(this.adminLogin, true).subscribe({
        next: (user) => {
          this.authService.masquerade(this.masqueradeLogin, user.token).subscribe({
            next: () => {
              this.alertService.showSuccess(`You are masquerading ${this.masqueradeLogin.email} now`, `Success!`);
              window.location.href = this.settings.settings.ORIG_SITE;
            },
            error: (errResponse: any) => {
              this.isLoading = false;
              this.alertService.showError(`${errResponse.error.error}`, `Error!`);
            },
          });
        },
        error: (errResponse: any) => {
          this.isLoading = false;
          this.alertService.showError(errResponse.error.details, ``);
        },
      });
    }
  }

  public hasError = (controlName: string, errorName: string): boolean => {
    return this.loginForm.controls[controlName].hasError(errorName);
  };
}
