import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MobileComponent } from './mobile/mobile.component';
import { MatButtonModule } from '@angular/material/button';

import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
  NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
} from 'ngx-google-analytics';
import Hotjar from '@hotjar/browser';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import 'hammerjs';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'chart.js';
import { provideEnvironmentNgxMask } from 'ngx-mask';

Chart.register(zoomPlugin, annotationPlugin);

import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { AlertComponent } from './shared/alert/alert.component';
import { appRoutes } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { effects, reducers } from './store';
import { environment } from '../environments/environment';
import { SettingsConfigService } from './shared/settings/settings-config.service';

@NgModule({
  declarations: [AppComponent, AlertComponent, MainLayoutComponent, MobileComponent],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    PagesModule,
    MatButtonModule,
    ToastrModule.forRoot({
      toastComponent: AlertComponent,
      positionClass: `toast-bottom-right`,
      progressBar: true,
      closeButton: true,
      disableTimeOut: false,
      tapToDismiss: false,
      newestOnTop: false,
      easeTime: 300,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    MatIconModule,
    MatProgressBarModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    {
      ngModule: NgxGoogleAnalyticsModule,
      providers: [
        {
          provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
          useFactory: (s: SettingsConfigService) => {
            return { trackingCode: s.settings.ANALYTICS_KEY };
          },
          deps: [SettingsConfigService],
        },
        NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
      ],
    },
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    CookieService,
    provideCharts(withDefaultRegisterables()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => init,
      multi: true,
    },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

function init(): void {
  if (environment.production) {
    const siteId = 3163673;
    const version = 6;
    Hotjar.init(siteId, version);
  }
}
