import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({ providedIn: `root` })
export class ErrorTextGeneratorService {
  constructor(private datePipe: DatePipe) {}

  public getErrorMessage(control: AbstractControl): string {
    if (control.hasError(`required`)) {
      return `This field is required`;
    }
    if (control.hasError(`min`)) {
      return `Must be at least ${control.getError(`min`).min}`;
    }
    if (control.hasError(`max`)) {
      return `Must be at most ${control.getError(`max`).max}`;
    }
    if (control.hasError(`matDatepickerParse`)) {
      return `Unrecognized date value`;
    }
    if (control.hasError(`notADate`)) {
      return `This field should have a numerical value`;
    }
    if (control.hasError(`notDateParsable`)) {
      return `This field should be a valid date`;
    }
    if (control.hasError(`minDate`)) {
      return `Must be at least ${this.datePipe.transform(control.getError(`minDate`).min, `short`)}`;
    }
    if (control.hasError(`maxDate`)) {
      return `Can't be greater than ${this.datePipe.transform(control.getError(`maxDate`).max, `short`)}`;
    }
    if (control.hasError(`matDatepickerMin`)) {
      return `Must be at least ${this.datePipe.transform(control.getError(`matDatepickerMin`).min, `short`)}`;
    }
    if (control.hasError(`matDatepickerMax`)) {
      return `Can't be greater than ${this.datePipe.transform(control.getError(`matDatepickerMax`).max, `short`)}`;
    }
    if (control.hasError(`exceededAnimalQuota`)) {
      return control.getError(`exceededAnimalQuota`);
    }
    if (control.hasError(`passwordStrength`)) {
      return control.getError(`passwordStrength`);
    }
    if (control.hasError(`email`)) {
      return `Invalid email address`;
    }
    if (control.hasError(`invalidDateRange`)) {
      return `Invalid date range`;
    }
    return `Wrong value`;
  }
}
