import { SingleHerdPaddockHistory } from './paddock.interface';

export class GrazeEvent {
  public start: SingleHerdPaddockHistory;
  public end: SingleHerdPaddockHistory;

  constructor(start?: SingleHerdPaddockHistory, end?: SingleHerdPaddockHistory) {
    this.start = start;
    this.end = end;
  }

  public getForage(): number {
    return GrazeEvent.getForage(this.start, this.end);
  }

  public static getForage(start: SingleHerdPaddockHistory, end: SingleHerdPaddockHistory): number {
    const msInDay = 1000 * 3600 * 24;
    if (end && start) {
      const feedPerDay = (start.herd_snapshots.total_feed_per_day + end.herd_snapshots.total_feed_per_day) / 2;
      const days = (Date.parse(end.record_date) - Date.parse(start.record_date)) / msInDay;
      return feedPerDay * days;
    }
    if (start) {
      const days = (Date.now() - Date.parse(start.record_date)) / msInDay;
      return start.herd_snapshots.total_feed_per_day * days;
    }
    return 0;
  }
}
