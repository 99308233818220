export interface Infrastructure {
  uuid: string;
  name: string;
  is_active: boolean;
  geometry: { type: string; coordinates: number[] };
  photo_url: string;
  notes: string;
  object_type: InfrastructureType;
  color: string;
  install_date: string;
  datetime_created: string;
  datetime_modified: string;
  ranch_uuid: string;
  season_uuid: string;
  removal_date: string;
  weight: number;
  deleted: boolean;
}

export const InfrastructureType = {
  Gate: `gate`,
  Bale: `bale`,
  RainGauge: `rain gauge`,
  WaterTrough: `water trough`,
  Other: `other`,
  SoilPoint: `soil point`,
} as const;
export type InfrastructureType = (typeof InfrastructureType)[keyof typeof InfrastructureType];

export function InfrastructureIcon(type: InfrastructureType): string {
  switch (type) {
    case InfrastructureType.Gate:
      return `gate`;
    case InfrastructureType.Bale:
      return `bale`;
    case InfrastructureType.RainGauge:
      return `rain`;
    case InfrastructureType.WaterTrough:
      return `water`;
    case InfrastructureType.Other:
      return `other`;
    case InfrastructureType.SoilPoint:
      return `soil`;
  }
}

export function InfrastructureColor(type: InfrastructureType): string {
  switch (type) {
    case InfrastructureType.Gate:
      return `#55413E`;
    case InfrastructureType.Bale:
      return `#CB9F60`;
    case InfrastructureType.RainGauge:
      return `#3A539B`;
    case InfrastructureType.WaterTrough:
      return `#4FA2CC`;
    case InfrastructureType.Other:
      return `#000`;
    case InfrastructureType.SoilPoint:
      return `#55413E`; // to be replaced with some other color
  }
}

export function InfrastructureName(type: InfrastructureType): string {
  switch (type) {
    case InfrastructureType.Gate:
      return `Gate`;
    case InfrastructureType.Bale:
      return `Bale`;
    case InfrastructureType.RainGauge:
      return `Rain Gauge`;
    case InfrastructureType.WaterTrough:
      return `Water Trough`;
    case InfrastructureType.Other:
      return `Other`;
    case InfrastructureType.SoilPoint:
      return `Soil Point`;
  }
}

export type InfrastructuresModel = {
  id: InfrastructureType;
  name: string;
  icon: string;
  color: string;
};
export const Infrastructures: InfrastructuresModel[] = [
  {
    id: InfrastructureType.Gate,
    name: InfrastructureName(InfrastructureType.Gate),
    icon: InfrastructureIcon(InfrastructureType.Gate),
    color: InfrastructureColor(InfrastructureType.Gate),
  },
  {
    id: InfrastructureType.Bale,
    name: InfrastructureName(InfrastructureType.Bale),
    icon: InfrastructureIcon(InfrastructureType.Bale),
    color: InfrastructureColor(InfrastructureType.Bale),
  },
  {
    id: InfrastructureType.RainGauge,
    name: InfrastructureName(InfrastructureType.RainGauge),
    icon: InfrastructureIcon(InfrastructureType.RainGauge),
    color: InfrastructureColor(InfrastructureType.RainGauge),
  },
  {
    id: InfrastructureType.WaterTrough,
    name: InfrastructureName(InfrastructureType.WaterTrough),
    icon: InfrastructureIcon(InfrastructureType.WaterTrough),
    color: InfrastructureColor(InfrastructureType.WaterTrough),
  },
  {
    id: InfrastructureType.Other,
    name: InfrastructureName(InfrastructureType.Other),
    icon: InfrastructureIcon(InfrastructureType.Other),
    color: InfrastructureColor(InfrastructureType.Other),
  },
  {
    id: InfrastructureType.SoilPoint,
    name: InfrastructureName(InfrastructureType.SoilPoint),
    icon: InfrastructureIcon(InfrastructureType.SoilPoint),
    color: InfrastructureColor(InfrastructureType.SoilPoint),
  },
];
