import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap } from 'rxjs';

import { SettingsUserService } from 'src/app/shared/settings/settings-user.service';
import { UserService } from './services/user.service';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
  getMe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.GetMe),
      mergeMap((action) =>
        this.userService.getMe().pipe(
          mergeMap((me) => {
            localStorage.setItem(`me`, JSON.stringify(me));
            this.sus.setRanchUUID(this.sus.checkRanchUUID(me.ranches[0]?.ranch_uuid));
            if (!this.sus.seasonUUID) {
              this.sus.setSeasonUUID(me.ranches[0]?.current_season_uuid);
            }
            return of(UserActions.GetMeSuccess({ me }));
          }),
          catchError(() => of(UserActions.GetMeFailure()))
        )
      )
    );
  });

  initUserState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.GetMeSuccess),
      mergeMap(() => [UserActions.GetSub(), UserActions.GetTeam()])
    );
  });

  getSubscription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.GetSub),
      mergeMap(() =>
        this.userService.getSub().pipe(
          mergeMap((sub) => {
            return of(UserActions.GetSubSuccess({ sub }));
          }),
          catchError(() => of(UserActions.GetSubFailure()))
        )
      )
    );
  });

  getTeam$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.GetTeam),
      mergeMap(() =>
        this.userService.getRanchTeam().pipe(
          mergeMap((team) => {
            return of(UserActions.GetTeamSuccess({ team }));
          }),
          catchError(() => of(UserActions.GetTeamFailure()))
        )
      )
    );
  });

  setRanchSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.SetRanchSettings),
      switchMap((action) =>
        this.userService.setRanchSettings(action.settings).pipe(
          mergeMap((me) => {
            return of(UserActions.SetRanchSettingsSuccess({ settings: me.settings }));
          }),
          catchError(() => of(UserActions.SetRanchSettingsFailure()))
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly userService: UserService,
    private readonly sus: SettingsUserService
  ) {}
}
