import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { merge } from 'rxjs';

import { CustomValidators } from 'src/app/shared/form-validators/custom-validators.class';
import { PasswordValidator } from 'src/app/shared/validators';
import { ErrorTextGeneratorService } from 'src/app/herds/dialogs/error-text-generator.service';
import { States } from 'src/app/shared/constants/states.constant';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { jwtDecode } from 'jwt-decode';

@UntilDestroy()
@Component({
  selector: `pasture-grc-join`,
  templateUrl: `./grc-join.component.html`,
  styleUrl: `./grc-join.component.scss`,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDividerModule,
    NgxMaskDirective,
    MatAutocompleteModule,
  ],
  providers: [ErrorTextGeneratorService, provideNgxMask(), AuthService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrcJoinComponent {
  @ViewChild(`stateInput`) stateInput: ElementRef<HTMLInputElement>;
  public form = new FormGroup({
    ranchName: new FormControl<string>(null, [Validators.required]),
    state: new FormControl(null, [Validators.required]),
    zip: new FormControl(null, [Validators.required]),

    title: new FormControl<Title>(null, [Validators.required]),
    firstName: new FormControl<string>(null, [Validators.required]),
    lastName: new FormControl<string>(null, [Validators.required]),
    email: new FormControl<string>(null, [Validators.required, CustomValidators.email]),
    password: new FormControl<string>(null, [Validators.required, PasswordValidator]),
    phone: new FormControl<string>(null, [Validators.required]),

    membersCount: new FormControl<number>(null, [Validators.required]),
    ranchType: new FormControl<RanchTypes>(null, [Validators.required]),

    preferedCommunication: new FormGroup({
      email: new FormControl<boolean>(false),
      phone: new FormControl<boolean>(false),
      text: new FormControl<boolean>(false),
      video: new FormControl<boolean>(false),
    }),
  });
  public filteredStates: string[];
  public states = States;
  public submitted = false;
  public visiblePassword = false;

  private invitationToken: string;

  constructor(
    public errorGen: ErrorTextGeneratorService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    this.filteredStates = [...this.states];
    this.invitationToken = this.route.snapshot.queryParams.invitation_token;

    merge(this.form.controls.firstName.valueChanges, this.form.controls.lastName.valueChanges)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const value = [this.form.controls.firstName.value, this.form.controls.lastName.value]
          .filter((a) => !!a)
          .join(` `);
        if (this.form.controls.ranchName.pristine) {
          if (value) {
            this.form.controls.ranchName.setValue(`${value}'s Ranch`);
          } else {
            this.form.controls.ranchName.setValue(null);
          }
        }
      });

    this.setDataFromToken(this.invitationToken);
  }

  public submit(): void {
    this.submitted = true;
    if (this.form.valid) {
      const form = this.form.value;
      const payload = {
        email: form.email,
        first_name: form.firstName,
        last_name: form.lastName,
        password: form.password,
        phone_number: form.phone,
        name: form.ranchName,
        contract_token: this.invitationToken,
        state: form.state,
        zip: form.zip,
        title: form.title,
        members_count: form.membersCount,
        ranch_type: form.ranchType,
        preferred_communication: form.preferedCommunication,
      };

      this.authService.createContractedRanch(payload).subscribe(() => {
        this.router.navigate([`/onboarding/step-1`]);
      });
    }
  }

  public filterStates(): void {
    const filterValue = this.stateInput.nativeElement.value.toLowerCase() ?? ``;
    this.filteredStates = this.states.filter((state) => state.toLowerCase().includes(filterValue));
  }

  private setDataFromToken(invitationToken: string): void {
    const tokenValue = <TokenModel>jwtDecode(invitationToken);
    const nonEmptyValues: any = {};

    if (tokenValue.email) {
      nonEmptyValues.email = tokenValue.email;
    }
    if (tokenValue.phone_number) {
      nonEmptyValues.phone = tokenValue.phone_number;
    }
    if (tokenValue.first_name) {
      nonEmptyValues.firstName = tokenValue.first_name;
    }
    if (tokenValue.last_name) {
      nonEmptyValues.lastName = tokenValue.last_name;
    }

    this.form.patchValue(nonEmptyValues);
  }
}

type Title = `mr` | `mrs` | `ms`;
type RanchTypes = `cow` | `stocker` | `dairy` | `contract` | `direct` | `seed` | `other`;
// type ComunicationTypes = `email` | `phone` | `text` | `video`;

type TokenModel = {
  contract_uuid: string;
  email: string;
  phone_number: string;
  first_name: string;
  last_name: string;
};
