<label [matMenuTriggerFor]="colorPick" (menuOpened)="toggleSelect()" (menuClosed)="toggleSelect()">
  <input type="hidden" value="{{ currentColor.value }}" />
  <div class="pseudo-select" [class.opened]="opened">
    <span class="icon" [style.color]="currentColor.value"></span>
    <span class="label">{{ currentColor.name }}</span>
  </div>
  <mat-menu #colorPick="matMenu" class="color-picker-menu">
    <ng-container *ngFor="let sColor of colors">
      <div class="color-picker-dropdown" [disabled]="sColor === currentColor" mat-menu-item (click)="pickColor(sColor)">
        <span class="icon" [style.color]="sColor.value"></span>
        <span class="label">{{ sColor.name }}</span>
      </div>
    </ng-container>
  </mat-menu>
</label>
