export class IconService {
  private imageMap: Map<string, HTMLImageElement> = new Map();

  public createImage(src: string): HTMLImageElement | Promise<HTMLImageElement> {
    if (this.imageMap.has(src)) {
      return this.imageMap.get(src);
    }
    const img = new Image();
    return new Promise((resolve, reject) => {
      img.onload = () => {
        this.imageMap.set(src, img);
        resolve(img);
      };
      img.onerror = reject;
      img.src = src;
    });
  }
}
